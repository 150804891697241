import 'controllers';
import 'channels';
import localStorageHandler from './main/application/localStorageHandler';
import autotyper from './main/application/autotyper.min';
import typingEffect from './main/application/typing_effect';
import initAttachinary from './main/application/init_attachinary';
import buyerAlert from './main/application/buyer_alert';
import citiesSearch from './main/application/cities_search';
import expertsCarousel from './main/application/experts_carousel';
import lastSalesCarousel from './main/application/last_sales_carousel';
import select2 from './main/application/select2';
import swiper from './main/application/swiper';
import select2DropDownPosition from './main/application/select2DropDownPosition';
import handleStopPropagation from './main/application/handleStopPropagation';
import HTMLElementsDeclarations from './main/application/HTMLElementsDeclarations';

document.addEventListener('DOMContentLoaded', () => {
  localStorageHandler();
  initAttachinary();
  autotyper();
  typingEffect();
  buyerAlert();
  expertsCarousel();
  lastSalesCarousel();
  select2DropDownPosition();
  select2();
  swiper();
  handleStopPropagation();
  citiesSearch();
  HTMLElementsDeclarations()
});
