import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = ['anchor']

  connect = () => {
    this.initializeIntersectionObserver();
  };

  triggerPagination = (e) => {
    const productCard = e.currentTarget
    const productCardIndex = productCard.dataset.productCardIndex
    const paginationEl = document.querySelector(`.swiper-pagination-bullet-${productCardIndex}`)
    this.removeActiveCard()
    productCard.classList.add('vesta-active')
    paginationEl.click()
  }

  triggerCard = (e) => {
    const productCard = e.currentTarget
    const productCardIndex = productCard.dataset.productPaginationIndex
    const paginationEl = document.querySelector(`[data-product-card-index='${productCardIndex}']`)
    this.removeActiveCard()
    paginationEl.classList.add('vesta-active')
  }

  removeActiveCard = () => {
    const activeProductCard = document.querySelector('.vesta-active')
    activeProductCard.classList.remove('vesta-active')
  }

  initializeIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(this.handleObserver, options);
    if (this.anchorTarget) {
      observer.observe(this.anchorTarget);
    }
  }

  handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      this.handleSwiperIntersection()
    } else {
      if(this.highlightSwiper?.mount) this.highlightSwiper.autoplay.stop()
    }
  }

  handleSwiper = () => {
    this.highlightSwiper = new Swiper('.swiper-product-highlight', {
      slidesPerView: 1,
      speed: 500,
      effect: 'fade',
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class='${className} ${className}-${index + 1}' data-action='click->application--swiper-product-highlight#triggerCard' data-product-pagination-index=${index + 1}></span>`
        },
      },
    });
  }

  handleAutoplay = () => {
    if(this.highlightSwiper?.mount) {
      this.highlightSwiper.on('autoplay', () => {
        const activeCardOld = document.querySelector('.vesta-active');
        activeCardOld.classList.remove('vesta-active')
        const activeSlideIndex = this.highlightSwiper.activeIndex + 1;
        const activeCard = document.querySelector(`.swiper-card-${activeSlideIndex}`);
        if (this.highlightSwiper.autoplay.running) {
          activeCard.classList.add('vesta-active')
        }
      });
    }
  }

  handleSwiperIntersection = () => {
    if(this.highlightSwiper?.mount) {
      this.highlightSwiper.autoplay.start()
    } else {
      this.handleSwiper()
      this.handleAutoplay()
    }
  }
}
