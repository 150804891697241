import { Controller } from '@hotwired/stimulus';
import priceFormatting from '../../shared/utils/priceFormatting'
export default class extends Controller {
  static targets = ['monthlyPayments', 'apport', 'loanDuration', 'apportSlider', 'loanDurationSlider', 'interestRate']
  static values = {
    price: Number,
    interestRatesTen: Number,
    interestRatesFifteen: Number,
    interestRatesTwenty: Number,
    interestRatesTwentyFive: Number
  }

  interestRates = {
    10: this.interestRatesTenValue,
    15: this.interestRatesFifteenValue,
    20: this.interestRatesTwentyValue,
    25: this.interestRatesTwentyFiveValue
  }

  connect() {
    this.loanDurationTarget.innerText = 20
    this.loanDurationSliderTarget.value = 20

    this.interestRateTarget.innerText = this.interestRates[20]

    const apportValue = 0.1 * (1.075 * this.priceValue)
    this.apportTarget.innerText = priceFormatting(apportValue)
    this.apportSliderTarget.value = apportValue

    this.monthlyPaymentsTarget.innerText = priceFormatting(this.computeMonthlyPayments())
  }

  changeLoanDuration(e) {
    this.loanDurationTarget.innerText = e.currentTarget.value
    this.interestRateTarget.innerText = this.interestRates[e.currentTarget.value]
    this.monthlyPaymentsTarget.innerText = priceFormatting(this.computeMonthlyPayments())
  }

  changeApport(e) {
    this.apportTarget.innerText = priceFormatting(e.currentTarget.value)
    this.monthlyPaymentsTarget.innerText = priceFormatting(this.computeMonthlyPayments())
  }

  computeMonthlyPayments() {
    const loanDurationInMonths = this.loanDurationSliderTarget.value * 12;
    const monthlyInterestRate = (this.interestRates[this.loanDurationSliderTarget.value]/ 100) / 12;
    const borrowedCapital = this.priceValue + 0.075 * this.priceValue - this.apportSliderTarget.value
    const monthlyPayments = (borrowedCapital * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, - loanDurationInMonths))

    return monthlyPayments < 0 ? 0 : monthlyPayments;
  }
}
