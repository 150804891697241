import { Controller } from '@hotwired/stimulus';
import priceFormatting from '../../shared/utils/priceFormatting'
export default class extends Controller {
  static targets = [
    'averageSellingPrice',
    'estimationVisits',
    'currentlyAwaitingOfferSales',
    'acceptedOffers',
    'sellRate',
    'sellTime',
    'averageDeltaBetweenOaAndMandatePrice'
  ]

  static values = {
    inseeCode: String
  }

  connect() {
    this.fetch_stats()
  }

  fetch_stats() {
    const url = `/admin/statistics?insee_code=${this.inseeCodeValue}`
    fetch(url).then(r => r.json().then((stats) => {
      this.displayStatistics(stats)
    }))
  }

  displayStatistics(stats) {
    const averageSellingPriceMeter = this.average_selling_price_per_meter(stats)
    this.averageSellingPriceTarget.innerText = isNaN(Number(averageSellingPriceMeter)) ? 'N/A' : `${priceFormatting(averageSellingPriceMeter)}/m²`
    this.currentlyAwaitingOfferSalesTarget.innerText = this.currently_awaiting_offer_sales(stats)
    this.acceptedOffersTarget.innerText = this.accepted_offers(stats)
    this.sellTimeTarget.innerText = Number.isNaN(this.sell_time(stats)) ? 'N/A' : `${this.sell_time(stats)}j`
  }

  average_selling_price_per_meter(stats) {
    try {
      const all_prices = stats.reduce((accumulator, currentValue) => {
        return accumulator + ((currentValue.average_selling_price_per_meter*currentValue.accepted_offers) || 0);
      }, 0);
      return Math.round(all_prices / this.accepted_offers(stats))
    } catch (error) {
      console.error(error)
    }
  }

  currently_awaiting_offer_sales(stats) {
    return stats.reduce((prev, curr) => {
      return prev + curr.currently_awaiting_offer_sales;
    }, 0)
  }

  accepted_offers(stats) {
    return stats.reduce((prev, curr) => {
      return prev + curr.accepted_offers;
    }, 0)
  }

  sell_time(stats) {
    const all_sell_times = stats.reduce((prev, curr) => {
      return prev + ((curr.sell_time*curr.accepted_offers) || 0);
    }, 0)
    return Math.round(all_sell_times / this.accepted_offers(stats))
  }
}
