import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  connect = () => {
    new Swiper('.swiper-simple-carousel', {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: '.next-estimate-steps',
        prevEl: '.prev-estimate-steps'
      }
    });
  };
}
