import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // action bound to left click "click"
  // whereTo must be a base64 encoded string
  goto(e) {
    if (e.ctrlKey || e.target.dataset.howTo === 'blank') {
      // open in new tab or new window
      window.open(atob(e.target.dataset.whereTo), '_blank').focus();
    } else {
      // go to new location in same tab
      document.location.href = atob(e.target.dataset.whereTo);
    }
  }

  // action bound to right click "contextmenu"
  nope(e) {
    e.preventDefault();
  }
}
