import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'autoSmsToVisitor', 'triggerAutoSmsToVisitor', 'visitorReachedDate', 'smsSentDate', 'callReminderToVisitor' ]

  toggleBtn = (e) => {
    const triggerBtn = e.currentTarget
    const url = e.currentTarget.dataset.url;
    const data = { visit_report: { client_reached: false } }

    triggerBtn.setAttribute('disabled', true)

    this.request_update(url, data, {
      success: () => {
        this.autoSmsToVisitorTarget.classList.toggle('hidden');
        triggerBtn.classList.toggle('hidden');
      },
      failure: () => {
        triggerBtn.removeAttribute('disabled', false);
        alertMessage("Les modifications n'ont pas pu être prises en compte", 'error')
      }
    });
  }

  markAsReached = (e) => {
    const triggerBtn = e.currentTarget
    const url = e.currentTarget.dataset.url;
    const data = { visit_report: { client_reached: true } }

    this.request_update(url, data, {
      success: () => {
        triggerBtn.classList.add('hidden');
        this.autoSmsToVisitorTarget.classList.add('hidden');
        this.triggerAutoSmsToVisitorTarget.classList.add('hidden');
        this.visitorReachedDateTarget.textContent = "Appelé à l'instant";
        this.visitorReachedDateTarget.classList.remove('hidden');
        if (this.hasSmsSentDateTarget) this.smsSentDateTarget.classList.add('hidden');
        if (this.hasCallReminderToVisitorTarget) this.callReminderToVisitorTarget.classList.add('hidden');
      }
    });
  }

  request_update(url, data, options) {
    fetch(url, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(this.handle_response_errors)
    .then(options.success)
    .catch(options.failure)
  }

  handle_response_errors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
}
