import { Controller } from '@hotwired/stimulus';
import SET_MAP from '../../config/mapbox/setMap';
import SET_LAYERS from '../../config/mapbox/setLayers';
import { FILL_LAYER_ID, LINE_LAYER_ID, SOURCE_ID, filterZone, FILL_LAYER_PAINT, LINE_LAYER_PAINT } from './mapbox_options'

export default class extends Controller {
  static targets = ['geocoder', 'inZoneAlert', 'zoneResult', 'cityCard']

  static values = {
    publicKey: String,
    style: String,
    regionLat: String,
    regionLng: String,
    zoom: Number,
    tilesetId: String
  }

  connect() {
    this.handleMapSettings()
    this.handleLayers()
    this.handleGeocoder()
    this.handleMapEvent()
  }

  handleMapSettings() {
    this.adminZoneMap = SET_MAP.initMap(this.publicKeyValue, [this.regionLngValue, this.regionLatValue], 9, this.styleValue, 'admin-map-cities')
    this.geocoder = SET_MAP.initGeocoder()
    this.adminZoneMap.addControl(this.geocoder);
  }

  handleLayers() {
    this.adminZoneMap.on('load', () => {
      SET_MAP.initSource(this.adminZoneMap, FILL_LAYER_ID, this.tilesetIdValue)

      SET_LAYERS.withOptions({
        map: this.adminZoneMap,
        layerId: FILL_LAYER_ID,
        type: 'fill',
        sourceId: SOURCE_ID,
        filter: filterZone('quarter'),
        paint: FILL_LAYER_PAINT
      })

      SET_LAYERS.withOptions({
        map: this.adminZoneMap,
        layerId: LINE_LAYER_ID,
        type: 'line',
        sourceId: SOURCE_ID,
        filter: filterZone('city'),
        paint: LINE_LAYER_PAINT
      })
    })
  }

  handleGeocoder() {
    // On geocoder result check addresse's zone and display it to user
    this.geocoder.on('result', (e) => {
      // Map has to be recentered to fix the reference point for queryRenderedFeatures() method
      this.adminZoneMap.flyTo({ center: [this.regionLngValue, this.regionLatValue] });

      this.zoneResultTarget.textContent = "";
      const geocoder_point = this.adminZoneMap.project([e.result.center[0], e.result.center[1]]);
      const query = this.adminZoneMap.queryRenderedFeatures(geocoder_point, { layers: ['zone-region'] });
      this.adminZoneMap.flyTo({ center: e.result.center });
      // Display result to user
      this.inZoneAlertTarget.classList.remove('vesta-bg-red-20');
      this.inZoneAlertTarget.classList.remove('vesta-bg-green-20');

      if (query[0]) {
        // Insert layer result to cities search field
        this.geocoderTarget.value = query[0].properties.insee_code
        this.filterCities();
        this.inZoneAlertTarget.classList.add('vesta-bg-green-20');
        this.zoneResultTarget.textContent = `Ce bien se trouve dans la zone de catégorie ${query[0].properties.zone_category_id}`;
      } else {
        this.inZoneAlertTarget.classList.add('vesta-bg-red-20');
        this.zoneResultTarget.textContent = "Ce bien est hors zone"
      }
    });
  }

  handleMapEvent() {
    // City search functions
    if (this.hasGeocoderTarget) {
      // Filter list on map click
      this.adminZoneMap.on('click', 'zone-region', (e) => {
        if (e.features.length > 0) {
          this.geocoderTarget.value = e.features[0].properties.insee_code
          this.filterCities();
        }
      });

      // Filter list on input change
      this.geocoderTarget.onkeyup = () => this.filterCities();
    }
  }

  filterCities = () => {
    // City filtering function
    const filter = this.geocoderTarget.value.toLowerCase();

    this.cityCardTargets.forEach(cityCard => {
      const name = cityCard.dataset.name;
      const inseeCode = cityCard.dataset.inseeCode;

      if ((name.toLowerCase().indexOf(filter) > -1) || (inseeCode.indexOf(filter) > -1)) {
        cityCard.style.display = "";
      } else cityCard.style.display = "none";
    });
  }
}
