import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'selectStatusForm' ];

  changeColor(e) {
    const regex = /status--.+/
    const colorClass = Array.from(e.currentTarget.classList).find(value => regex.test(value))
    e.currentTarget.classList.remove(colorClass)
    e.currentTarget.classList.add(`status--${e.currentTarget.value}`)
  }
}
