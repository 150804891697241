import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['prequalifiedCheckbox']

  managePrequalified = () => {
    const url = `${window.location.origin}/api/v1/visits/${this.prequalifiedCheckboxTarget.dataset.visitId}`

    fetch(url, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({prequalified: this.prequalifiedCheckboxTarget.checked})
    })
  }
}
