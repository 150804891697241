import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['intersectionAnchor', 'toggle']

  connect() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.toggleTarget.classList.remove('vesta-visible--fade');
          this.toggleTarget.classList.add('vesta-hidden--fade');
        } else {
          this.toggleTarget.classList.remove('vesta-hidden--fade');
          this.toggleTarget.classList.add('vesta-visible--fade');
        }
      });
    }, { threshold: 0.1 });

    observer.observe(this.intersectionAnchorTarget);
  }
}
