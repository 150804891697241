import { Controller } from '@hotwired/stimulus';
import slideUp from '../../packs/main/application/slide_up.js'
import slideDown from '../../packs/main/application/slide_down.js'
export default class extends Controller {
  static targets = ['repeatLoanCondition',
                    'loanConditionInfo',
                    'apportInfo',
                    'offerSubmitFinancials',
                    'submitPromiseOfFinancing',
                    'financePromiseDocDiv',
                    'financePromiseIconSuccess',
                    'docToUpload',
                    'docUploaded',
                    'prettoCardInfo',
                    'openPrettoSection',
                    'loadingLayerSizedAbsolute']

  // -------------------- Offer conditions --------------------
  active = (e) => {
    const radioBtnValue = e.currentTarget.value
    if(radioBtnValue === 'true') {
      this.repeatLoanConditionTarget.classList.remove('hidden')
    } else {
      this.repeatLoanConditionTarget.classList.add('hidden');
    }
  }

  toggleLoanConditionInfo = () => {
    this.loanConditionInfoTarget.classList.toggle('hidden');
  }

  toggleApportInfo = () => {
    this.apportInfoTarget.classList.toggle('hidden');
  }

  // -------------------- Offer financials --------------------
  toggleLoanPartner = (e) => {
    if (!this.hasOfferSubmitFinancialsTarget) {
      this.offerSubmitFinancialsTarget = this.submitPromiseOfFinancingTarget;
    }
    if (this.hasOfferSubmitFinancialsTarget) {
      if (e.currentTarget.checked === true) {
        this.offerSubmitFinancialsTarget.disabled = false;
      } else {
        this.offerSubmitFinancialsTarget.disabled = true;
      }
    }
  }

  openPrettoSection = (e) => {
    if (this.prettoCardInfoTarget.style.display == 'block') {
      e.currentTarget.childNodes[1].classList.remove('selected-card')
      slideUp(this.prettoCardInfoTarget, 200);
      // TODO replace with pure JS
      $("html, body").animate({ scrollTop: 0 }, "slow");
    } else {
      e.currentTarget.childNodes[1].classList.add('selected-card')
      slideDown(this.prettoCardInfoTarget, 200);
      // TODO replace with pure JS
      $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
    }
  }

  redirectToUpload = (e) => {
    slideUp(this.prettoCardInfoTarget, 200);
    this.openPrettoSectionTarget.childNodes[1].classList.remove('selected-card')
    e.target.classList.add('selected-card');
    this.loadingLayerSizedAbsoluteTarget.style.display = 'flex';
  }

  // -------------------- Offer promise of financing --------------------
  fileInput = (event) => {
    setTimeout(() => {
      if (event.target.value !== '') {
        this.submitPromiseOfFinancingTarget.disabled = false;
        this.submitPromiseOfFinancingTarget.removeAttribute("id")
        this.financePromiseIconSuccessTarget.classList.remove('hidden');
        this.financePromiseDocDivTarget.classList.add('saledoc-card--success')
        this.docToUploadTarget.classList.add('hidden');
        this.docUploadedTarget.classList.remove('hidden');
      }
    }, 1000);
  }
}
