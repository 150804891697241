export const pushVirtualPageView = () => {
  window.dataLayer = window.dataLayer || [];

  const path = window.location.pathname
  const [region, city, quarter] = path.split('/').filter(el => Boolean(el) && el !== 'prix-immobilier-m2')

  window.dataLayer.push({
    'event':            'VirtualPageview',
    'virtualPageURL':    window.location.pathname,
    'virtualPageTitle': `Page ${region || "d'Acceuil"}${city ? ` - ${city}` : ''}${quarter ? ` - ${quarter}` : ''}`
  });
}
