import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['pap', 'agency', 'sellState', 'sellProbability', 'comment', 'submitButton', 'tooltip']

  connect() {
    if (this.hasPapTarget) this.changeColor(this.papTarget)
    if (this.hasAgencyTarget) this.changeColor(this.agencyTarget)
    if (this.hasSellStateTarget) this.changeColor(this.sellStateTarget)
    if (this.hasSellProbabilityTarget) this.changeColor(this.sellProbabilityTarget)
    if (this.hasCommentTatget) this.checkFiftyChars(this.commentTarget)
  }

  select(event) {
    this.changeColor(event.target)
  }

  input(event) {
    this.checkFiftyChars(event.target)
  }

  changeColor(target) {
    let colors = JSON.parse(target.dataset.color);
    let color = colors.find(pair => pair[0] === target.value);

    target.className = '';
    target.classList.add('competition-input');
    if (color === undefined) return;

    target.classList.add('vesta-' + color[1] + '-100');
  }


  checkFiftyChars(target) {
    if (target.value.trim().length < 20) {
      this.submitButtonTarget.disabled = true
      this.tooltipTarget.dataset.originalTitle = this.tooltipTarget.dataset.warning
    } else {
      this.submitButtonTarget.disabled = false
      this.tooltipTarget.dataset.originalTitle = ''
    }
  }
}
