import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'selectFameReason', 'saleUniqueH', 'userEmail', 'email', 'userId', 'btsOrigin' ]


  connect() {
    this.openForms()

    this.selectFameReasonTarget.onchange = () => {
      this.closeForms()
      this.openForms()
    }
  }

  openForms() {
    const value = this.selectFameReasonTarget.selectedOptions[0].dataset.slug
    switch(value) {
      case 'sponsor_client':
      case 'reco_client':
        this.saleUniqueHTarget.classList.remove('vesta-display-none');
        this.userEmailTarget.classList.remove('vesta-display-none');
        return
      case 'sponsor_no_client':
        this.emailTarget.classList.remove('vesta-display-none');
        return
      case 'perso_prospect':
        this.userIdTarget.classList.remove('vesta-display-none');
        return
      case 'buyer_to_seller':
        this.btsOriginTarget.classList.remove('vesta-display-none');
        return
    }
  }

  closeForms() {
    ['saleUniqueHTarget', 'userEmailTarget', 'userIdTarget', 'emailTarget', 'btsOriginTarget'].forEach((form) => {
      this[form].classList.add('vesta-display-none');
      [...this[form].getElementsByTagName('input')].forEach(input => input.value = null);
      [...this[form].getElementsByTagName('select')].forEach(input => input.value = '' )
    })
  }
}
