const lastSalesCarousel = () => {
  $('.carousel-init-class').each(function() {
    const slider = $(this);
    let nextArrow = '';
    let prevArrow = '';
    if (slider.hasClass('secondary-carousel')) {
      nextArrow = $('.secondary-carousel-next');
      prevArrow = $('.secondary-carousel-prev');
    }
    else {
       nextArrow = $('.last-sales-carousel-next');
       prevArrow = $('.last-sales-carousel-prev');
    }
    slider.slick({
      lazyLoad: 'ondemand',
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      responsive: [
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 418,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
  });
}


export default lastSalesCarousel;
