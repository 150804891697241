import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item', 'totalPages', 'currentPage', 'previousPageButton', 'nextPageButton', 'buttons']

  connect() {
    this.perPage = 5
    this.showPage(1)
  }

  showPage(page) {
    this.disabledValue = false
    const start = (page - 1) * this.perPage
    const end = start + this.perPage
    this.itemTargets.forEach((item, index) => item.style.display = index >= start && index < end ? "" : "none")
    this.currentPageTarget.innerText = page
    this.totalPagesTarget.innerText = this.pageCount
    this.handleDisabledButtons(page === 1, this.previousPageButtonTarget)
    this.handleDisabledButtons(page >= this.pageCount, this.nextPageButtonTarget)
  }

  nextPage() {
    const currentPage = this.currentPage
    if (currentPage < this.pageCount) {
      this.showPage(currentPage + 1)
    }
  }

  previousPage() {
    const currentPage = this.currentPage
    if (currentPage > 1) {
      this.showPage(currentPage - 1)
    }
  }

  handleDisabledButtons(reachedPageLimit, button) {
    if (reachedPageLimit) button.disabled = true
    else button.disabled = false
  }

  get currentPage() {
    return Math.ceil(Array.from(this.itemTargets).findIndex(item => item.style.display !== "none") / this.perPage + 1)
  }

  get pageCount() {
    return Math.ceil(this.itemTargets.length / this.perPage)
  }

  search(event) {
    if (event.target.value.length === 0) {
      this.showPage(1)
      this.buttonsTarget.style.display = ""
      return
    }

    this.buttonsTarget.style.display = 'none'

    const searchTerm = event.target.value.trim().toLowerCase()

    this.filteredItems = this.itemTargets.filter(item => {
      const email = item.dataset.email.toLowerCase()
      return email.includes(searchTerm)
    })

    this.renderFilteredItems()
    this.currentPageTarget.innerText = this.currentPage
  }

  renderFilteredItems() {
    this.itemTargets.forEach(item => {
      item.style.display = this.filteredItems.includes(item) ? "" : "none"
    })
  }
}
