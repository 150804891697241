const FILL_LAYER_ID = 'zone-region'
const LINE_LAYER_ID = 'zone-region-outline'
const SOURCE_ID = 'zone-region'

const filterZone = (zoneType) => {
  return ['all', ['match', ['get', 'hosman_class'], ['', `${zoneType.charAt(0).toUpperCase()}${zoneType.slice(1)}`], true, false]]
}


const LAYER_COLOR = [
  'match',
  ['get', 'zone_category_id'],
  ['1'],
  'hsl(235, 100%, 37%)',
  ['2'],
  'hsl(0, 100%, 37%)',
  ['3'],
  'hsl(115, 100%, 34%)',
  ['4'],
  'hsl(56, 100%, 42%)',
  ['5'],
  'hsl(29, 100%, 57%)',
  '#000000'
]

const FILL_LAYER_OPACITY = [
  'match',
  ['get', 'zone_category_id'],
  ['1', '2', '3', '4', '5', '0'],
  0.2,
  0
]

const FILL_LAYER_PAINT = {
  'fill-color': LAYER_COLOR,
  'fill-opacity': FILL_LAYER_OPACITY
}

const LINE_LAYER_PAINT = {
  'line-color': LAYER_COLOR,
  'line-width': 1
}

export { FILL_LAYER_ID, LINE_LAYER_ID, SOURCE_ID, filterZone, FILL_LAYER_PAINT, LINE_LAYER_PAINT }
