const buyerAlert = () => {
  if ($.fn.select2) {
    $.fn.select2.amd.define(
      'select2/data/googleAutocompleteAdapter',
      ['select2/data/array', 'select2/utils'],
      function (ArrayAdapter, Utils) {
        function GoogleAutocompleteDataAdapter($element, options) {
          GoogleAutocompleteDataAdapter.__super__.constructor.call(
            this,
            $element,
            options
          );
        }

        Utils.Extend(GoogleAutocompleteDataAdapter, ArrayAdapter);

        GoogleAutocompleteDataAdapter.prototype.query = function (
          params,
          callback
        ) {
          const returnSuggestions = function (predictions, status) {
            var data = { results: [] };
            if (predictions !== null) {
              if (status != google.maps.places.PlacesServiceStatus.OK) {
                callback(data);
              }
              for (var i = 0; i < predictions.length; i++) {
                data.results.push({
                  id: predictions[i].description,
                  text: predictions[i].description,
                });
              }
            }
            callback(data);
          };

          if (params.term) {
            const options = {
              input: params.term,
              componentRestrictions: { country: 'fr' },
              types: ['(regions)'],
            };
            const service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions(options, returnSuggestions);
          } else {
            const data = { results: [] };
            callback(data);
          }
        };
        return GoogleAutocompleteDataAdapter;
      }
    );

    var googleAutocompleteAdapter = $.fn.select2.amd.require(
      'select2/data/googleAutocompleteAdapter'
    );

    $('.select-2-address-autocomplete').select2({
      width: '100%',
      dataAdapter: googleAutocompleteAdapter,
      placeholder: 'Ville, arrondissement, code postal...',
      escapeMarkup: function (markup) {
        return markup;
      },
      minimumInputLength: 2,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection,
    });
  }

  function formatRepo(repo) {
    if (repo.loading) {
      return repo.text;
    }
    var markup =
      "<div class='select2-result-repository clearfix'>" +
      "<div class='select2-result-title'>" +
      repo.text +
      '</div>';
    return markup;
  }

  function formatRepoSelection(repo) {
    return repo.text;
  }
};

export default buyerAlert;
