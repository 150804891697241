import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = [ 'searchOption' ]
  static values = { placeholder: String }

  connect() {
    this.slim = new SlimSelect({
      select: this.element,
      settings: {
        showSearch: this.hasSearchOptionTarget,
        placeholderText: this.placeholderValue
      },
      events: {
        afterChange: (newVal) => {
          if (newVal[0].value === '') this.applyPlaceholderChanges(this.slim.settings.id)
        }
      }
    })

    if (this.slim.getSelected()[0] === '') this.applyPlaceholderChanges(this.slim.settings.id)
  }

  applyPlaceholderChanges = (optionId) => {
    const placeholderIdContainer = document.querySelector(`.ss-main[data-id=${optionId}]`);
    placeholderIdContainer.querySelector('.ss-single').classList.add('vesta-purple-40-important')
  }

  destroy() {
    this.slim.destroy()
  }
}
