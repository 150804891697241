import { Controller } from '@hotwired/stimulus';;

export default class extends Controller {
  static targets = ["firstStep", "finishedFirstStep", "previewSecondStep", "secondStep", "buttonSave", "errorMessage"]
  connect() {
    this.listenIframe()
    this.timer = null;
    this.seconds = 0;
  }

  paymentCardStep() {
    this.removeClass([this.firstStepTarget, this.previewSecondStepTarget, this.secondStepTarget], 'vesta-display-none')
    this.addClass([this.buttonSaveTarget], 'vesta-display-none')
    this.firstStepTarget.scrollIntoView({ behavior: 'smooth', block: 'center'})
    this.startTimer()
  }

  selectSlotStep() {
    this.addClass([this.firstStepTarget, this.previewSecondStepTarget], 'vesta-display-none')
    this.removeClass([this.secondStepTarget, this.finishedFirstStepTarget], 'vesta-display-none')
    this.removeDisabledSlot()
    this.secondStepTarget.scrollIntoView({ behavior: 'smooth', block: 'center'})
    this.stopTimer();
  }

  removeDisabledSlot() {
    let slots = document.getElementsByClassName('disabled-slot');
    this.removeClass([...slots], 'disabled-slot');
  }

  debugSlotStep() {
    setTimeout(() => {
      this.selectSlotStep();
    }, 2000);
  }

  listenIframe() {
    document.getElementById('payment-diag-iframe').onload = () => {
      const paymentFrame = document.getElementById('payment-diag-iframe');
      const frameWindowLocation = paymentFrame.contentWindow.location.href;
      if ((!frameWindowLocation) || (frameWindowLocation && !paymentFrame.contentWindow.location.hash)) return;

      const hash = paymentFrame.contentWindow.location.hash;
      if (hash === "#success") {
        setTimeout(() => {
          this.selectSlotStep();
        }, 2000);
      }
    }
  }

  startTimer() {
    this.stopTimer();
    this.seconds = 0;
    this.timer = setInterval(() => {
      this.seconds++;

      if (this.seconds >= 45) {
        this.errorMessageTarget.classList.remove('vesta-display-none');
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  removeClass = (elements, className) => elements.forEach(element => element.classList.remove(className))

  addClass = (elements, className) => elements.forEach(element => {element.classList.add(className)})
}
