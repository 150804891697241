import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  show = () => {
    const target = this.contentTargets[0];
    if (target.classList.contains('active')) {
      target.classList.remove('active');
    }
    else {
      target.classList.add('active');
    }
  }
}
