import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  toggle_value = async (event) => {
    const field_name = event.currentTarget.dataset.value
    const url = event.currentTarget.closest('.team-members__leads').dataset.url;
    const switch_slider = event.currentTarget.querySelector('.toggle-btn-team-member-leads');
    
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({changed_field: field_name})
    })
    
    if(response.ok) { 
      alertMessage('Les modifications ont bien été prises en compte', 'success');
      switch_slider.classList.toggle('active');
    } else {
      const error = await response.json()
      alertMessage(error.message, 'error');
    }
  }
}
