import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submit', 'emailInput', 'warning']

  connect() {
    $(this.emailInputTarget).select2({
      placeholder: 'jean@dupont.com, marie@dupont.fr, ...',
      tags: true,
      tokenSeparators: [',', ' '],
    }).on('change', () => {
      this.checkAccents();
    });
  }

  checkAccents() {
    const emails = $(this.emailInputTarget).val() || [];
    const hasAccents = emails.some(email => !/^[\x00-\x7F]*$/.test(email));
    if (hasAccents) {
      this.warningTarget.classList.remove('vesta-display-none');
      this.submitTarget.disabled = true;
    } else {
      this.warningTarget.classList.add('vesta-display-none');
      this.submitTarget.disabled = false;
    }
  }
}
