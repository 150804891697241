import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    uniqueHash: String
  }

  connect() {
    if(this.hasUniqueHashValue) {
      this.pushDataLayer('view_advert_page')
    }
  }

  viewLocation() {
    this.pushDataLayer('view_location')
  }

  viewVirtualVisit() {
    this.pushDataLayer('view_virtual_visit')
  }

  viewPlan() {
    this.pushDataLayer('view_plan')
  }

  viewStreetView() {
    this.pushDataLayer('view_street_view')
  }

  pushDataLayer(event) {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': event,
      'unique_hash': this.uniqueHashValue
    })
  }
}
