import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'inlineCta', 'articleBody' ]

  connect() {
    let ctaLinks = [...this.inlineCtaTargets].map((ctaSpan) => ctaSpan.closest('a'));

    let articlesLinks = this.articleBodyTarget.getElementsByTagName('a');

    [...articlesLinks].map((link) => {
      link.setAttribute('target', '_blank');
      // add style to non CTA links beacuse Trix removes it
      if (![...ctaLinks].includes(link)) {
        if (link.text.includes('→')) {
          link.classList.add('vesta-green-link');
        } else if (!link.classList.contains('vesta-button--primary')) {
          link.classList.add('vesta-blue-link');
        } 
        link.classList.add('vesta-text-deco-none');
      }
    });
  }
}
