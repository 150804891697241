import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = ['swiper'];
  static values = {
    secondPhoto: String
  };

  connect = () => {
    new Swiper('.swiper-property-card-panel-photos', {
      centeredSlides: true,
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.next-sale-photo',
        prevEl: '.prev-sale-photo'
      }
    });
  };
}
