import SET_LAYERS from '../../../config/mapbox/setLayers';
import MAP_EVENTS from './mapEvents';
import PARTIALS from './partials';

import { generateLayerId, filterZone, fillPaint, linePaint, SOURCE_ID } from '../mapbox_options'

const UPDATE_LAYERS = {
  handleLayerStylingForFranceAndAbove() {
    if (this.geoDataMap.getLayer('geo-datas-fill-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-fill-quarter')
    if (this.geoDataMap.getLayer('geo-datas-line-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-line-quarter')
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', 'transparent');
    this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', this.COLORS['blue-100']);
    if (this.ZOOM_OUT && this.STEP_ZOOM !== 'france') {
      this.STEP_ZOOM = 'france'
      PARTIALS.updatePageData({ baseUrl: this.BASE_URL, step_type: this.STEP_ZOOM });
      // Reinitialize
      this.LAST_CLICKED_ZONES = {}
    }
    // if zoom out initialize url
    if (this.ZOOM_OUT) {
      history.pushState({}, '', `${this.BASE_URL}`);
      document.title = 'Prix immobilier'
    }

    if (this.CURRENT_MARKERS.length > 0) this.CURRENT_MARKERS.forEach((marker) => marker.remove());
  },

  handleLayerStylingBetweenFranceAndRegion() {
    if (this.geoDataMap.getLayer('geo-datas-fill-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-fill-quarter')
    if (this.geoDataMap.getLayer('geo-datas-line-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-line-quarter')
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', this.COLORS['blue-100']);
    this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', this.COLORS['blue-100']);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-width', 1);
    if (this.ZOOM_OUT && this.STEP_ZOOM !== 'region') {
      this.STEP_ZOOM = 'region'
      if (this.LAST_CLICKED_ZONES.region != undefined) PARTIALS.updatePageData({ baseUrl: this.BASE_URL, step_type: this.STEP_ZOOM, record_id: this.LAST_CLICKED_ZONES.region, record_type: 'Region' });
      else if (this.LAST_CLICKED_ZONES.city != undefined) PARTIALS.updatePageData({ baseUrl: this.BASE_URL, step_type: this.STEP_ZOOM, record_id: this.LAST_CLICKED_ZONES.city, record_type: 'City' });
    }
    if (this.CURRENT_MARKERS.length > 0) this.CURRENT_MARKERS.forEach((marker) => marker.remove());
  },

  handleLayoutStylingBetweenRegionAndCity() {
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', this.COLORS['blue-100']);
    this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', this.COLORS['blue-100']);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-width', 1);
    if (this.CURRENT_MARKERS.length > 0) this.CURRENT_MARKERS.forEach((marker) => marker.remove());
    // Add quarter layer to allow hover and click
    if (this.geoDataMap.getLayer('geo-datas-line-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-line-quarter');
    // Add targeted city style
    this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-width', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, 3, 1]);

    if (this.ZOOM_OUT && this.STEP_ZOOM !== 'city') {
      if (this.geoDataMap.getLayer('geo-datas-fill-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-fill-quarter');
      this.STEP_ZOOM = 'city';
      if (this.LAST_CLICKED_ZONES.city != undefined) PARTIALS.updatePageData({ baseUrl: this.BASE_URL, step_type: this.STEP_ZOOM, record_id: this.LAST_CLICKED_ZONES.city, record_type: 'City' });
    }
  },

  handleLayoutStylingBetweenCityAndQuarter() {
    if (this.geoDataMap.getLayer('geo-datas-fill-quarter') === undefined) {
      SET_LAYERS.withOptions({
        map: this.geoDataMap,
        layerId: generateLayerId('fill', 'quarter'),
        sourceId: SOURCE_ID,
        type: 'fill',
        filter: filterZone('quarter'),
        paint: fillPaint(this.COLORS['purple-100'], this.COLORS['neutral-40'])
     });
    }

    if (this.geoDataMap.getLayer('geo-datas-line-quarter') === undefined) {
      SET_LAYERS.withOptions({
        map: this.geoDataMap,
        layerId: generateLayerId('line', 'quarter'),
        sourceId: SOURCE_ID,
        type: 'line',
        filter: filterZone('quarter'),
        paint: linePaint()
     });
    }
    // Add targeted quarter style
    this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-width', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.city, 3, 1]);

    this.geoDataMap.setPaintProperty('geo-datas-fill-quarter', 'fill-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.quarter, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-quarter', 'line-color', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.quarter, this.COLORS['blue-100'], this.COLORS['blue-100']]);
    this.geoDataMap.setPaintProperty('geo-datas-line-quarter', 'line-width', ['match', ['get', 'hosman_id'], this.LAST_CLICKED_ZONES.quarter, 3, 1]);
    // If zoom in and quarter id present add properties markers
    if (this.ZOOM_IN && this.LAST_CLICKED_ZONES.quarter !== undefined) MAP_EVENTS.propertiesMarkers(this.geoDataMap, this.LAST_CLICKED_ZONES.quarter, this.CURRENT_MARKERS);
    // If zoom out we want to remove quarter layer to be able to click new city
    if (this.ZOOM_OUT && this.STEP_ZOOM !== 'quarter' && this.geoDataMap.getLayer('geo-datas-fill-quarter') !== undefined) this.geoDataMap.removeLayer('geo-datas-fill-quarter');
    this.STEP_ZOOM = 'quarter';
  },
};

export default UPDATE_LAYERS;
