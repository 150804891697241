import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['containerNavAdvert', 'advertNav'];

  connect() {
    this.handleScroll();
  }

  handleScroll = () => {
    let lastScrollChange = 0;

    window.onscroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll > lastScrollChange) {
        this.advertNavTarget.classList.add('vesta-flex-important-mobile');
        lastScrollChange = currentScroll
      }

      if (currentScroll === 0 || currentScroll < lastScrollChange - 100) {
        this.advertNavTarget.classList.remove('vesta-flex-important-mobile');
        lastScrollChange = currentScroll
      }
    };
  }
}
