const generateLayerId = (kind, zone) => {
  return `geo-datas-${kind}-${zone}`
}

const filterZone = (zoneType) => {
  return ['all', ['==', 'active', true], ['==', 'hosman_class', `${zoneType.charAt(0).toUpperCase()}${zoneType.slice(1)}`]]
}

const fillPaint = (hoveredColor, color) => {
  return {
    'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], hoveredColor, color],
    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.6, 0.3]
  }
}

const linePaint = () => {
  return {
    'line-color': 'transparent',
    'line-width': 0
  }
}

const SOURCE_ID = 'geo-datas'

export { generateLayerId, filterZone, fillPaint, linePaint, SOURCE_ID }
