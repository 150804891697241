import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'inputConfirmation', 'label', 'email', 'confirmationLabel', 'submitButton'];

  connect() {
    // Sometimes and because of different browser, config, passord manager etc.. the new password input field is autocompleted even with [autocomplete: 'new-password'] mentionned in the input.
    // This is a workaround to force the new password input field to be empty (only on first page load). setTimeout is used to wait for password manager autocomplete on input to be done.
    setTimeout(() => {
      this.inputTargets.forEach((input) => {
        if (input.value !== '') input.value = ''
      })
    }, 300)
  }

  validatePassword() {
    const password = this.inputTarget.value;
    const minLength = 8;
    if (password.length >= minLength) {
      this.labelTarget.classList.remove('vesta-label--purple-20');
      this.labelTarget.classList.add('vesta-label--green-20');
      const passwordConfirmationContainer = document.getElementById('passwordConfirmationContainer');
      const passwordConfirmationNotDisplay = passwordConfirmationContainer && passwordConfirmationContainer.classList.contains('vesta-display-none');
      if (!this.hasInputConfirmationTarget || passwordConfirmationNotDisplay) this.submitButtonTarget.disabled = false;
      else this.validatePasswordConfirmation();
    } else {
      this.labelTarget.classList.remove('vesta-label--green-20');
      this.labelTarget.classList.add('vesta-label--purple-20');
      if (!this.hasInputConfirmationTarget) this.submitButtonTarget.disabled = true;
    }
  }

  validatePasswordConfirmation() {
    const password = this.inputTarget.value;
    const confirmation = this.inputConfirmationTarget.value;
    // Only fire the label error if confirmation meet the password length and the passwords are not the same
    const errorThreshold = password.length <= confirmation.length;
    const inputsFilled = password.length >= 8 && errorThreshold;
    const passwordsMatch = password === confirmation;

    if (inputsFilled) {
      this.confirmationLabelTarget.classList.toggle('vesta-display-none', passwordsMatch);
      this.confirmationLabelTarget.classList.toggle('vesta-label', !passwordsMatch);
      this.submitButtonTarget.disabled = !passwordsMatch;
    } else {
      this.confirmationLabelTarget.classList.add('vesta-display-none');
      this.confirmationLabelTarget.classList.remove('vesta-label');
      this.submitButtonTarget.disabled = true;
    }
  }

  toggleVisibility(e) {
    const input = e.currentTarget.dataset.input;
    const type = this[input].getAttribute('type') === 'password' ? 'text' : 'password';
    this[input].setAttribute('type', type);
    e.currentTarget.classList.toggle('fa-eye-slash')
    e.currentTarget.classList.toggle('fa-eye')
  }
}
