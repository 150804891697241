import consumer from "./consumer";

const visitContainer = document.querySelector('.visit-container');
if (visitContainer) {
  consumer.subscriptions.create({
    channel: 'VisitsCalendarChannel',
    property_id: visitContainer.dataset.propertyid
    },
    {
      received(data) {
      // Called when there's incoming data on the websocket for this channel
      const trigger = document.getElementById('visit-calendar-channel-trigger')
      if (!trigger) return null;

      if (trigger.dataset.controller == 'admin/properties/visits' && trigger.dataset.action == 'index' ) {
        alertMessage(`${data.message}`, `${data.type}`)
      }
    }
  });
}
