import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'progressionBar' ]

  update = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    this.progressionBarTarget.style.width = scrolled + "%";
  }
}
