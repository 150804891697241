const SET_MARKERS = {
  main(map, center, popup = false, className) {
    this.divElement = document.createElement('div');
    this.divElement.className = className || 'hosman-marker';

    const marker = new mapboxgl.Marker(this.divElement)
    .setLngLat(center)
    .addTo(map);

    if(popup !== false) marker.setPopup(popup)
    return marker
  },

  specific(map, item) {
    const divEl = document.createElement('div');
    divEl.id = `vesta-marker-${item.state.replace(/_/g, '-')}`;

    new mapboxgl.Marker(divEl)
      .setLngLat({ lat: item.lat, lng: item.lng })
      .addTo(map);
  }
}

export default SET_MARKERS;
