const select2 = () => {
  // TODO investigate to use pure JS instead of jQuery
  let select2List = $('.select2-list');

  if (select2List.length > 0) select2List.select2();

  if ($('#search-zone').length > 0) {
    const onlineEstimationUrl = $('#mapbox-zones')[0].dataset.online;

    $('#search-zone').closest('body').css({ 'overflow-x': 'hidden' });

    $('#search-zone').select2({
      placeholder:
        '<span class="typo-input vesta-purple-50">Rechercher une ville, un arrondissement</span>',
      allowClear: true,
      dropdownPosition: 'below',
      escapeMarkup: function (markup) {
        return markup;
      },
      language: {
        noResults: function () {
          return `
                  <div class='vesta-text-center'>
                    <span class='typo-content'>
                      Hosman n'est pas encore présent dans votre ville, mais en attendant,
                      nous vous mettons à disposition notre moteur d'estimation en ligne
                    </span>
                    <div class='vesta-flex-row-center vesta-margin-y-40'>
                      <a href=${onlineEstimationUrl} class="vesta-button--primary vesta-button--large">Faire une estimation en ligne</a>
                    </div>
                  </div>
                `;
        }
      }
    });
  }

  if ($('#departments_selection').length > 0) {
    $('#departments_selection').select2({
      placeholder: 'Sélectionner un département',
      allowClear: true
    });
  }

  if ($('#regions_selection').length > 0) {
    $('#regions_selection').select2({
      placeholder: 'Sélectionner une région',
      allowClear: true
    });
  }

  if ($('#buyer_alert_phone_country_code').length > 0) {
    $('#buyer_alert_phone_country_code').select2({
      dropdownParent: $('#buyer-alert-admin')
    });
  }

  $(document).on('cocoon:after-insert', function (e, insertedItem) {
    select2List = $('.select2-list');
    if (select2List.length > 0) select2List.select2();
  });

  if (document.getElementsByClassName('multiple-cities-select').length > 0) {
    $('.multiple-cities-select').select2();
  }

  if (document.getElementsByClassName('multiple-exposure-select').length > 0) {
    $('.multiple-exposure-select').select2({
      maximumSelectionLength: 3,
      language: {
        maximumSelected: function (e) {
          return 'Vous ne pouvez sélectionner que ' + e.maximum + ' valeurs';
        }
      }
    });
  }

  if (document.getElementsByClassName('multiple-states-select').length > 0) {
    $('.multiple-states-select').select2({
      placeholder: 'State du bien'
    });
  }

  if (document.getElementsByClassName('multiple-categories-select').length > 0) {
    $('.multiple-categories-select').select2({
      placeholder: "Catégorie d'acheteur"
    });
  }

  if (document.getElementsByClassName('multiple-status-select').length > 0) {
    $('.multiple-status-select').select2({
      placeholder: 'Statut'
    });
  }

  if (document.getElementsByClassName('multiple-bedroom-select').length > 0) {
    $('.multiple-bedroom-select').select2({
      placeholder: '2'
    });
  }

  if (document.getElementsByClassName('multiple-outdoor-space-select').length > 0) {
    $('.multiple-outdoor-space-select').select2({
      placeholder: 'Pas nécessairement'
    });
  }

  if (document.getElementsByClassName('multiple-floor-select').length > 0) {
    $('.multiple-floor-select').select2({
      placeholder: 'Peu importe'
    });
  }


  if (document.getElementsByClassName('multiple-elevator-select').length > 0) {
    $('.multiple-elevator-select').select2({
      placeholder: 'Si 1er étage ou plus'
    });
  }

  // if (document.getElementsByClassName('select2-property-advert-autocomplete').length > 0) {
  //   $('.select2-property-advert-autocomplete').select2({
  //     placeholder: 'State du bien'
  //   });
  // }

  if (document.getElementsByClassName('select-2-state-expertmap').length > 0) {
    $('.select-2-state-expertmap').select2();
  }

  if (document.getElementsByClassName('select-2-floor-expertmap').length > 0) {
    $('.select-2-foor-expertmap').select2();
  }

  if ($('#competitors-list').length > 0) {
    $('#competitors-list').select2({
      placeholder: 'Rechercher un compétiteur'
    });
  }
};

export default select2;
