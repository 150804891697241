import { fetchProperties, fetchProperty } from '../../../api/properties'

import priceFormatting from '../../../../shared/utils/priceFormatting';

const MAP_EVENTS = {
  flyToClickedZone(zoom, center) {
    this.geoDataMap.flyTo({ center, zoom });
  },

  async propertiesMarkers(map, hosmanId, markers) {
    const properties = await fetchProperties(hosmanId);

    if (markers.length > 0) {
      markers.forEach((marker) => marker.remove());
    }

    if (properties.length > 0) {
      properties.forEach((property) => {
        initMarker(map, property, markers);
      });
    }
  },
};

export default MAP_EVENTS;

async function initMarker(map, propertyPin, markers) {
  // create the marker
  const divEl = document.createElement('div');
  divEl.id = `vesta-marker-${propertyPin.state.replace(/_/g, '-')}`;

  const marker = new mapboxgl.Marker(divEl)
    .setLngLat({ lat: propertyPin.lat, lng: propertyPin.lng })
    .addTo(map);

  // Handle transform scale property via js because mapbox already use transform property as inline css
  const marketEl = marker.getElement()
  marketEl.addEventListener('mouseenter', function() {
    marketEl.style.transform = `${marketEl.style.transform} scale(1.3)`;
    marketEl.style.zIndex = '1';
  });

  marketEl.addEventListener('click', async function() {
    const property = await fetchProperty(propertyPin.id);
    initPopup(map, property);
  });

  marketEl.addEventListener('mouseleave', function() {
    marketEl.style.transform = `${marketEl.style.transform.split("scale(1.3)").join('').trim()}`;
    marketEl.style.zIndex = '0';
  });

  markers.push(marker);
};

function initPopup(map, property) {
  let picture_url = property.pictures[0]
  if (window.innerWidth <= 991 && picture_url.includes('c_fill')) picture_url = picture_url.replace('c_fill', 'c_scale')

  const propertyPopup = document.getElementById('property-popup')

  propertyPopup.innerHTML =
    `
    <a href=${property.url} class='position-relative vesta-card typo-content vesta-mapbox-popup fade-in-fast' target='_blank'>
      <div>

        <img src=${picture_url}>
        ${`
          <div class='vesta-image-footer vesta-image-footer--${property.sale.advert_label_sold !== null ? 'sold' : 'on-sale'} typo-caption'>
            ${property.sale.advert_label_sold !== null ? `${property.sale.advert_label_sold} ${property.signature_date ? 'en' : ''} ${property.signature_date ? property.signature_date.split(" ").splice(-2).join(' ') : '' }` : 'Bien à vendre'}
          </div>
        `}
      </div>

      <div class='vesta-flex-col-justify-center vesta-padding-10'>
        <div class='vesta-text-ellipsis typo-bold-content'>${property.advert_title}</div>
        <div class='typo-content'>${property.street_name || 'Adresse non précisée'}</div>

        <div class='vesta-property-infos'>
          <span> ${Math.round(property.kind === 'house' ? property.area : property.carrez_area)}m²</span>
          <span>${property.room_number}p</span>
          <span>${displayFloor(property)}</span>
          <span>${property.bedroom_number}c</span>
        </div>

        <div class='vesta-flex-row-between vesta-flex-wrap'>
          <div class='vesta-label--tertiary vesta-flex-col-center vesta-flex-wrap'>
            <div>${priceFormatting(property.sale.price)}</div>
            <div>${priceFormatting(Math.round(property.sale.price / Number(property.area)))}/m2</div>
          </div>
          <button class='vesta-button--primary vesta-button--small'>Voir l'annonce</button>
        </div>
      </div>
      <a class='position-absolute' id='quit-popup'>
        <i class='fas fa-times pointer-cursor'></i>
      </a>
    </a>
    `

  handlePopupEvents()
}

const displayFloor = (property) => {
  const kind = property.kind
  const floor = kind === 'apartment' ? property.floor : property.total_floor
  if (!floor) return '';

  switch (floor) {
    case 0:
      return kind === 'house' ? 'Plain pied' : 'Rdc';
    case 1:
      return (
        `<span>
          1${kind === 'house' ? 'étage' : `<sup>er</sup>étage`}
        </span>`
      );
    default:
      return (
        `<span>
          ${floor}
          ${kind === 'house' ? 'étages' : `<sup>e</sup>ét`}
        </span>`
      );
  }
};

const handlePopupEvents = () => {
  const popupElement = document.getElementById('property-popup')
  popupElement.addEventListener('click', function(e) {
    e.stopPropagation();
  });

  document.getElementById('quit-popup').addEventListener('click', function() {
    popupElement.innerHTML = ''
  });

  document.addEventListener('click', function() {
    popupElement.innerHTML = ''
  });
}
