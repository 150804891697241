import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'block' ];

  connect = () => {
    //hide timeline blocks which are outside the viewport
    this.blockTargets.forEach((block) => {
      if(this.getOffset(block).top > window.scrollY + window.innerHeight * 1) {
        block.querySelectorAll('.cd-timeline-img, .cd-timeline-content').forEach(block_find => {
          block_find.classList.add('is-hidden');
        })
      }
    });
  }

  observeIntersection = () => {
       this.blockTargets.forEach((block) => {
        if(this.getOffset(block).top <= window.scrollY + window.innerHeight * 1 &&
        block.querySelector('.cd-timeline-img').classList.contains('is-hidden') ) {
          block.querySelectorAll('.cd-timeline-img, .cd-timeline-content').forEach(block_find => {
            block_find.classList.remove('is-hidden');
            block_find.classList.add('bounce-in');
          })
        }
      });
  }

  getOffset(element){
    if (!element.getClientRects().length)
    {
      return { top: 0, left: 0 };
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return (
    {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    });
  }
}
