async function buyerAlertsMatch(saleUniqueHash, price) {
  const response = await fetch(`/api/v1/sales/${saleUniqueHash}/buyer_alerts?price=${price}`, {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
  });

  return await response.json()
}

export { buyerAlertsMatch };
