import { Controller } from '@hotwired/stimulus';
import { gunzipSync } from 'zlib';
const Buffer = require('buffer/').Buffer;

import SET_MAP from '../../config/mapbox/setMap';
import SET_LAYERS from '../../config/mapbox/setLayers';

import { generateLayerId, filterZone, fillPaint, linePaint, SOURCE_ID } from './mapbox_options'

import UPDATE_LAYERS from './functions/updateLayers';
import MAP_EVENTS from './functions/mapEvents';
import PARTIALS from './functions/partials';
import { pushVirtualPageView } from './functions/pushVirtualPageView';

export default class extends Controller {
  REGION_NAME = { paris: 'region-parisienne',nantes: 'region-nantaise', bordeaux: 'region-bordelaise', lyon: 'region-lyonnaise' };
  COLORS = { 'blue-100': '#5076F6', 'purple-100': '#21175A', 'neutral-30': '#4B485F', 'neutral-40': '#BCBAC3', transparent: 'transparent' };
  STATE_ZOOM = {
    france: 5,
    region: 9,
    city: 11,
    quarter: 13
  };
  STEP_ZOOM = 'france';
  INITIAL_CENTER = [1.7191, 46.7];
  CURRENT_MARKERS = [];
  LAST_CLICKED_ZONES = {};
  LAST_UPDATED_ZONES = {};
  PREVIOUS_ZOOM = this.STATE_ZOOM.france;
  CURRENT_ZOOM = this.STATE_ZOOM.france;
  ZOOM_OUT = false;
  ZOOM_IN = false;
  HOVERED_STATE_ID = null;
  BASE_URL = '/prix-immobilier-m2';
  PREVIOUS_URL = '';
  FEATURES = [];
  MAP_RENDERED = false;

  static targets = [
    'propertyKind',
    'propertyKindRadio',
    'title',
    'url',
    'mobileGeocoder',
    'apartmentInput',
    'houseInput',
    'houseContentCard',
    'apartmentContentCard',
    'propertyKindTitle',
    'modal',
    'firstPanel'
  ];
  // lat, lng and hosmanId are used only for first load to get record info to set up map and content
  static values = {
    publicKey: String,
    style: String,
    tilesetId: String,
    stepState: String,
    cityId: String,
    recordType: String,
    lat: String,
    lng: String,
    hosmanId: Number
  };

  connect = () => {
    this.changeTitle()
    this.handleScrollActions();

    this.geoDataMap = SET_MAP.initMap(this.publicKeyValue, this.INITIAL_CENTER, this.STATE_ZOOM.france, this.styleValue, 'geo-datas');

    this.geoDataMap.on('load', async () => {
      SET_MAP.initSource(this.geoDataMap, 'geo-datas', this.tilesetIdValue);

      SET_LAYERS.withOptions({
        map: this.geoDataMap,
        layerId: generateLayerId('fill', 'city'),
        sourceId: SOURCE_ID,
        type: 'fill',
        filter: filterZone('city'),
        paint: fillPaint(this.COLORS['purple-100'], this.COLORS['blue-100'])
      });

      SET_LAYERS.withOptions({
          map: this.geoDataMap,
          layerId: generateLayerId('line', 'city'),
          sourceId: SOURCE_ID,
          type: 'line',
          filter: filterZone('city'),
          paint: linePaint()
      });

      if (this.geoDataMap.getZoom() >= this.STATE_ZOOM.quarter) {
        MAP_EVENTS.propertiesMarkers(this.geoDataMap, this.hosmanIdValue, this.CURRENT_MARKERS);
      }

      this.geoDataMap.on('idle', async () => {
        if (this.MAP_RENDERED) return;

        this.initMapControl();
        this.initMapEvent();

        if (this.hosmanIdValue) {
          this.geoDataMap.setPaintProperty('geo-datas-fill-city', 'fill-color', ['match', ['get', 'hosman_id'], this.hosmanIdValue, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
          this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-color', ['match', ['get', 'hosman_id'], this.hosmanIdValue, this.COLORS['blue-100'], this.COLORS['neutral-40']]);
          this.geoDataMap.setPaintProperty('geo-datas-line-city', 'line-width', ['match', ['get', 'hosman_id'], this.hosmanIdValue, 3, 1]);
          MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM[this.recordTypeValue.toLowerCase()], { lng: Number(this.lngValue), lat: Number(this.latValue) });
          // If url is initialy set for quarter get city id as last clicked zone to retrieve it if zoom out
          if (this.hasCityIdValue) this.LAST_CLICKED_ZONES.city = parseInt(this.cityIdValue);
          // Retrieve current zone and save it as last clicked zone
          this.LAST_CLICKED_ZONES[this.recordTypeValue.toLowerCase()] = parseInt(this.hosmanIdValue);
        }

        this.MAP_RENDERED = true;
        this.LAST_UPDATED_ZONES = this.LAST_CLICKED_ZONES
      });
    });
  };

  initMapControl = async () => {
    // Retrieve dataset features to feed geocoder data search by our own data (geojson.gp needed for performance)
    const response = await fetch('https://res.cloudinary.com/vesta-home/raw/upload/v1677602714/assets/city_geojson/features.geojson.gz')
    const buffer = await response.arrayBuffer()
    const uncompressed = gunzipSync(Buffer.from(buffer));
    const result = uncompressed.toString('utf-8');
    const data = await JSON.parse(result)
    this.FEATURES = await data.features;

    // Geocoder for desktop (inside the map)
    this.geocoder = SET_MAP.initLocalGeocoder(this.FEATURES);
    // Geocoder for mobile (outside the map)
    if (this.hasMobileGeocoderTarget && window.innerWidth < 991) {
      this.geocoder_mobile = SET_MAP.initLocalGeocoder(this.FEATURES);
      this.mobileGeocoderTarget.appendChild(this.geocoder_mobile.onAdd(this.geoDataMap));
    }
    const nav = new mapboxgl.NavigationControl();
    this.geoDataMap.addControl(this.geocoder);
    this.geoDataMap.addControl(nav, 'top-left');

    if (this.hasMobileGeocoderTarget && window.innerWidth < 991) {
      this.geocoder_mobile.on('result', (e) => {
        this.handleGeocoderSearch(e);
      });
    } else {
      this.geocoder.on('result', (e) => {
        this.handleGeocoderSearch(e);
      });
    }
  };

  handleGeocoderSearch = (e) => {
    const { hosman_id, hosman_class, center, region, slug, city_slug } = e.result.properties;

    MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM[hosman_class.toLowerCase()], center);
    PARTIALS.updatePageData({ mapOnly: true, baseUrl: this.BASE_URL, step_type: hosman_class.toLowerCase(), record_id: hosman_id, record_type: hosman_class });
    MAP_EVENTS.propertiesMarkers(this.geoDataMap, hosman_id, this.CURRENT_MARKERS);
    const aggregSlug = `${this.BASE_URL}/${this.REGION_NAME[region.toLowerCase()]}${city_slug !== undefined ? `/${city_slug}/` : '/'}${slug}`
    history.pushState({}, '', aggregSlug);
    this.changeTitle()
    this.LAST_CLICKED_ZONES[hosman_class.toLowerCase()] = hosman_id;
  };

  // ******** Map events start ******** //
  initMapEvent = () => {
    // As there is no region data via mapbox layer we can't add event as for city and quarter layers. Workaround is made in city layer click event
    // Handle city layer click (wich handle both region and city)
    this.geoDataMap.on('click', 'geo-datas-fill-city', (e) => {
      const currentZoom = this.geoDataMap.getZoom();
      const { hosman_id, hosman_class, center } = e.features[0].properties;
      // Prevent map to reload if click on a marker
      if (e.originalEvent.target.classList.contains('mapboxgl-marker')) return;

      // If zoom position between France and region and click, city layer and fly at zoom region level (because mapbox layer doesn't have region data)
      if (this.LAST_CLICKED_ZONES.city === undefined && currentZoom < this.STATE_ZOOM.region) {
        // Fly to clicked zone at region state zoom level
        MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.region, JSON.parse(center));
        // Update right block content specifying step_type to manage partial rendering in geo_data_controller
        PARTIALS.updatePageData({ mapOnly: true, baseUrl: this.BASE_URL, step_type: 'region', record_id: hosman_id, record_type: hosman_class });
        this.LAST_CLICKED_ZONES = { city: hosman_id }
        // If zoom position gretter or equal to zoom region, zoom to city level
      } else if (this.LAST_CLICKED_ZONES.city === undefined && currentZoom >= this.STATE_ZOOM.region) {
        MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.city, JSON.parse(center));
        PARTIALS.updatePageData({ mapOnly: true, baseUrl: this.BASE_URL, step_type: 'city', record_id: hosman_id, record_type: hosman_class });
        this.LAST_CLICKED_ZONES = { city: hosman_id }
        // If last click zone city not undefined AND there is no quarter layer, zoom to city level
        // We check if no quarter layer because otherwise function (like updatePageData) are called twice: once in city layer / once in quarter layer
        // We can't remove quarter layer for hover and click purpose when on a city step
      } else if (this.LAST_CLICKED_ZONES.city !== undefined && this.geoDataMap.queryRenderedFeatures(e.point, { layers: ['geo-datas-fill-quarter'] }).length <= 0) {
        MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.city, JSON.parse(center));
        PARTIALS.updatePageData({ mapOnly: true, baseUrl: this.BASE_URL, step_type: 'city', record_id: hosman_id, record_type: hosman_class });
        this.LAST_CLICKED_ZONES = { city: hosman_id }
      }
    });

    // Handle quarter layer click
    this.geoDataMap.on('click', 'geo-datas-fill-quarter', async (e) => {
      if (e.originalEvent.target.classList.contains('mapboxgl-marker')) return;

      const { hosman_id, hosman_class, center } = e.features[0].properties;
      MAP_EVENTS.propertiesMarkers(this.geoDataMap, hosman_id, this.CURRENT_MARKERS);
      MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.quarter, JSON.parse(center));
      PARTIALS.updatePageData({ mapOnly: true, baseUrl: this.BASE_URL, step_type: 'quarter', record_id: hosman_id, record_type: hosman_class });

      const quarterCityId = this.geoDataMap.queryRenderedFeatures(e.point, { layers: ['geo-datas-fill-city'] })[0].properties.hosman_id
      this.LAST_CLICKED_ZONES = { city: parseInt(quarterCityId), quarter: hosman_id }
    });

    // This general event click on the map is used to handle dynamic url changes
    this.geoDataMap.on('click', (e) => {
      // Reset input value field
      const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder input');
      geocoderInput.value = '';
      // Check layer precense to handle url nomenclature by layer data
      const [quarter] = this.geoDataMap.queryRenderedFeatures(e.point, { layers: ['geo-datas-fill-quarter'] });
      const [city] = this.geoDataMap.queryRenderedFeatures(e.point, { layers: ['geo-datas-fill-city'] });

      if (quarter !== undefined) {
        // To update city focus if quarter from other city is clicked
        if (Number(this.LAST_CLICKED_ZONES.city) !== city.properties.hosman_id) this.LAST_CLICKED_ZONES.city = city.properties.hosman_id;
        if (Number(this.LAST_CLICKED_ZONES.quarter) !== quarter.properties.hosman_id) this.LAST_CLICKED_ZONES.quarter = quarter.properties.hosman_id;

        const aggregSlug = `${city.properties.slug}/${quarter.properties.slug}`;
        history.pushState({}, '', `${this.BASE_URL}/${this.REGION_NAME[city.properties.region.toLowerCase()]}/${aggregSlug}`);
      } else if (city !== undefined && this.geoDataMap.getZoom() > this.STATE_ZOOM.france) {
        history.pushState({}, '', `${this.BASE_URL}/${this.REGION_NAME[city.properties.region.toLowerCase()]}/${city.properties.slug}`);
      } else if (city !== undefined) {
        history.pushState({}, '', `${this.BASE_URL}/${this.REGION_NAME[city.properties.region.toLowerCase()]}`);
      }
      this.changeTitle()
    });

    this.geoDataMap.on('zoomstart', () => {
      this.PREVIOUS_ZOOM = this.geoDataMap.getZoom();
    });

    this.geoDataMap.on('zoomend', () => {
      this.CURRENT_ZOOM = this.geoDataMap.getZoom();
      this.ZOOM_OUT = this.CURRENT_ZOOM < this.PREVIOUS_ZOOM;
      this.ZOOM_IN = this.CURRENT_ZOOM > this.PREVIOUS_ZOOM;
      switch (true) {
        case this.CURRENT_ZOOM <= this.STATE_ZOOM.france:
          UPDATE_LAYERS.handleLayerStylingForFranceAndAbove.apply(this);
          break;
        case this.CURRENT_ZOOM > this.STATE_ZOOM.france && this.CURRENT_ZOOM <= this.STATE_ZOOM.region:
          UPDATE_LAYERS.handleLayerStylingBetweenFranceAndRegion.apply(this);
          break;
        case this.CURRENT_ZOOM > this.STATE_ZOOM.region && this.CURRENT_ZOOM < this.STATE_ZOOM.city:
          UPDATE_LAYERS.handleLayoutStylingBetweenRegionAndCity.apply(this);
          break;
        case this.CURRENT_ZOOM >= this.STATE_ZOOM.city && this.CURRENT_ZOOM <= this.STATE_ZOOM.quarter:
          UPDATE_LAYERS.handleLayoutStylingBetweenCityAndQuarter.apply(this);
          break;
        default:
          SET_LAYERS.withOptions({
            map: this.geoDataMap,
            layerId: generateLayerId('fill', 'city'),
            sourceId: SOURCE_ID,
            type: 'fill',
            filter: filterZone('city'),
            paint: fillPaint(this.COLORS['purple-100'], this.COLORS['blue-100'])
          });
          break;
      }
      pushVirtualPageView()
    });

    this.handleMouseEvent('mousemove', 'geo-datas-fill-city');
    this.handleMouseEvent('mouseleave', 'geo-datas-fill-city');
    this.handleMouseEvent('mousemove', 'geo-datas-fill-quarter');
    this.handleMouseEvent('mouseleave', 'geo-datas-fill-quarter');
  };

  handleMouseEvent = (eventType, layerId) => {
    this.geoDataMap.on(eventType, layerId, (event) => {
      // If HOVERED_STATE_ID not null reset the hovered features id to false to refresh hover state at each event
      if (this.HOVERED_STATE_ID !== null) this.geoDataMap.setFeatureState({ source: 'geo-datas', sourceLayer: 'Hosman', id: this.HOVERED_STATE_ID }, { hover: false });
      // On mousemove event apply hover on featured overed and save its id in HOVERED_STATE_ID for hover state purpose
      if (eventType === 'mousemove') {
        this.HOVERED_STATE_ID = event.features[0].id;
        this.geoDataMap.setFeatureState({ source: 'geo-datas', sourceLayer: 'Hosman', id: this.HOVERED_STATE_ID }, { hover: true });
        // Handle hovered + last clicked quarter style
        if (layerId == 'geo-datas-fill-quarter') {
          this.HOVERED_STATE_QUARTER_ID = event.features[0].properties.hosman_id;
          this.geoDataMap.setPaintProperty('geo-datas-fill-quarter', 'fill-color', [
            'match',
            ['get', 'hosman_id'],
            this.HOVERED_STATE_QUARTER_ID,
            this.COLORS['purple-100'],
            this.LAST_CLICKED_ZONES.quarter,
            this.COLORS['blue-100'],
            'transparent'
          ]);
          this.geoDataMap.setPaintProperty('geo-datas-fill-quarter', 'fill-opacity', ['match', ['get', 'hosman_id'], this.HOVERED_STATE_QUARTER_ID, 0.2, this.LAST_CLICKED_ZONES.quarter, 0.5, 0]);

          if (this.CURRENT_ZOOM > this.STATE_ZOOM.quarter) {
            this.geoDataMap.setPaintProperty('geo-datas-line-quarter', 'line-color', [
              'match',
              ['get', 'hosman_id'],
              this.HOVERED_STATE_QUARTER_ID,
              this.COLORS['purple-100'],
              this.LAST_CLICKED_ZONES.quarter,
              this.COLORS['blue-100'],
              'transparent'
            ]);

            this.geoDataMap.setPaintProperty('geo-datas-line-quarter', 'line-width', ['match', ['get', 'hosman_id'], this.HOVERED_STATE_QUARTER_ID, 1, this.LAST_CLICKED_ZONES.quarter, 3, 1]);
          }
        }
      } else {
        this.geoDataMap.setFeatureState({ source: 'geo-datas', sourceLayer: 'Hosman', id: this.HOVERED_STATE_ID }, { hover: false });
        this.HOVERED_STATE_ID = null
      };
    });
  };

  handleScrollActions = () => {
    const dynamicTitleH2 = document.getElementById('vesta-dynamic-title-h2');
    document.onscroll = () => {
      if (isInViewport(dynamicTitleH2)) {
        if (!this.LAST_CLICKED_ZONES.city) return;

        if (!this.LAST_CLICKED_ZONES.quarter && this.LAST_CLICKED_ZONES.city !== this.LAST_UPDATED_ZONES.city) {
          PARTIALS.updatePageData({ mapOnly: false, baseUrl: this.BASE_URL, step_type: 'city', record_id: this.LAST_CLICKED_ZONES.city, record_type: 'City' });
          this.LAST_UPDATED_ZONES.city = this.LAST_CLICKED_ZONES.city;
        }

        if (this.LAST_CLICKED_ZONES.quarter && this.LAST_CLICKED_ZONES.quarter !== this.LAST_UPDATED_ZONES.quarter && this.CURRENT_ZOOM <= this.STATE_ZOOM.quarter) {
          PARTIALS.updatePageData({ mapOnly: false, baseUrl: this.BASE_URL, step_type: 'quarter', record_id: this.LAST_CLICKED_ZONES.quarter, record_type: 'Quarter' });
          this.LAST_UPDATED_ZONES.quarter = parseInt(this.LAST_CLICKED_ZONES.quarter);
        }
      }
    };
  }

  // ******** Map events end ******** //

  // ******** Actions start ******** //
  selectZone = async (e) => {
    const { recordId, recordType, stepType, center, propertyKind, recordSlug, recordRegion } = e.currentTarget.dataset;
    MAP_EVENTS.propertiesMarkers(this.geoDataMap, recordId, this.CURRENT_MARKERS);
    MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM[stepType], JSON.parse(center));
    PARTIALS.updatePageData({ mapOnly: false, baseUrl: this.BASE_URL, step_type: stepType, record_id: recordId, record_type: recordType, property_kind: propertyKind });
    this.LAST_CLICKED_ZONES[recordType.toLowerCase()] = Number(recordId);
    if (stepType === 'region') {
      history.pushState({}, '', `${this.BASE_URL}/${this.REGION_NAME[recordRegion.toLowerCase()]}`);
      this.changeTitle()
    }

    if (stepType === 'city') {
      history.pushState({}, '', `${this.BASE_URL}/${this.REGION_NAME[recordRegion.toLowerCase()]}/${recordSlug}`);
      this.changeTitle()
    }
  };

  selectPropertyKind = () => {
    setTimeout(() => {
      if (this.apartmentInputTarget.checked && !this.houseInputTarget.checked) {
        this.houseContentCardTargets.forEach((target) => target.classList.add('vesta-display-none'));
        this.apartmentContentCardTargets.forEach((target) => target.classList.remove('vesta-display-none'));
        this.propertyKindTitleTarget.innerHTML = 'appartement';
      } else if (!this.apartmentInputTarget.checked && this.houseInputTarget.checked) {
        this.apartmentContentCardTargets.forEach((target) => target.classList.add('vesta-display-none'));
        this.houseContentCardTargets.forEach((target) => target.classList.remove('vesta-display-none'));
        this.propertyKindTitleTarget.innerHTML = 'maison';
      }
    }, 100);
  };

  togglePropertyKind = (e) => {
    const inputEl = document.getElementById(e.currentTarget.dataset.label);
    if (inputEl.checked) return;

    this.propertyKindRadioTargets.forEach((target) => {
      target.classList.toggle('vesta-display-none');
    });
  };

  backToFranceStep = () => {
    this.STEP_ZOOM = 'region';
    MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.france, this.INITIAL_CENTER);
    history.pushState({}, '', `${this.BASE_URL}`);
    this.changeTitle()
  };

  backToRegionStep = (e) => {
    this.STEP_ZOOM = 'city';
    const { recordId, path, recordLat, recordLng } = e.currentTarget.dataset;
    this.LAST_CLICKED_ZONES.region = Number(recordId);
    MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.region, { lng: Number(recordLng), lat: Number(recordLat) });
    history.pushState({}, '', `${this.BASE_URL}/${path}`);
    this.changeTitle()
  };

  backToCityStep = (e) => {
    this.STEP_ZOOM = 'quarter';
    const { recordId, path, recordLat, recordLng } = e.currentTarget.dataset;
    this.LAST_CLICKED_ZONES.city = Number(recordId);
    MAP_EVENTS.flyToClickedZone.call(this, this.STATE_ZOOM.city - 1, { lng: Number(recordLng), lat: Number(recordLat) });
    history.pushState({}, '', `${this.BASE_URL}/${path}`);
    this.changeTitle()
  };

  toggleModal = () => {
    this.modalTargets.forEach((modal) => modal.classList.toggle('vesta-display-none'));
    const modalAnchor = document.getElementById('vesta-modal-anchor');
    const uspAnchor = document.getElementById('vesta-usp-anchor');
    if (modalAnchor) modalAnchor.scrollIntoView();
    else uspAnchor.scrollIntoView();
    // Because modal open after first DOM scan, need to handle first opening panel (accrodion_controller evaluate scrollHeight at 0)
    setTimeout(() => {
      this.firstPanelTarget.style = 'max-height: 100%;';
    }, 500);
  };

  changeTitle() {
    const [region, city, quarter] = window.location.pathname.split('/').filter(el => Boolean(el) && el !== 'prix-immobilier-m2')
    document.title = `Prix immobilier ${region ? `Région ${capitalize(region.split('-')[1])}` : ''}${city ? ` - ${capitalize(city.replace(/-/g, ' '))}` : ''}${quarter ? ` - ${capitalize(quarter.replace(/-/, ' '))}` : ''}`
  }
  // ******** Actions END ******** //
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
