const SET_MAP = {
  initMap(apiKey, center, zoom, style, containerId) {
    mapboxgl.accessToken = apiKey;

    return new mapboxgl.Map({
      container: containerId,
      center: center,
      zoom: zoom,
      style: style
    });
  },

  initSource(map, sourceId, tilesetId) {
    map.addSource(sourceId, {
      type: 'vector',
      url: `mapbox://${tilesetId}`,
      promoteId: 'mapbox_id'
    });
  },

  initLocalGeocoder(features) {
    return new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      flyTo: {
        zoom: 12
      },
      placeholder: 'Rechercher une ville, un quartier...',
      marker: false,
      localGeocoder: (query) => customGeocoder(query, features),
      localGeocoderOnly: true
    });
  },

  initGeocoder() {
    return new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'fr',
      types: 'address'
    })
  }
};

export default SET_MAP;

function customGeocoder(query, features) {
  const matchingFeatures = features.filter(({ properties }) => properties.name.toLowerCase().includes(query.toLowerCase())).filter(({ properties }) => properties.active);

  const options = [];
  for (const feature of matchingFeatures) {
    feature['place_name'] = feature.properties.name;
    options.push(feature);
  }

  const uniqueOptions = options.filter((value, index, self) => index === self.findIndex((f) => f.place_name === value.place_name));
  return uniqueOptions;
}
