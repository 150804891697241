import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  stylize = () => {
    const select2Dropdown = document.querySelector('.select2-dropdown--below');
    const select2SearchField = document.querySelector('.select2-search__field');
    if (select2Dropdown) select2Dropdown.classList.add('vesta-select2-dropdown--below');
    if (select2SearchField) select2SearchField.classList.add('vesta-select2-search__field');
  }

  adjustTopPosition = () => {
    const select2Dropdown = document.querySelector('.select2-dropdown.select2-dropdown--below');
    if(select2Dropdown) {
      select2Dropdown.style.position = 'relative';
      select2Dropdown.style.top = '-41px';
    }
  }

  customHeaderInput = (e) => {
    const searchZonesInput = document.getElementById('search-zones');
    const select2SearchField = document.querySelector('.select2-search__field');
    const select2Results = document.querySelector('.select2-results');
    // If in Zone page => custom select2 as design system header input
    if(searchZonesInput && select2SearchField) {
      select2SearchField.style.borderRadius = '20px'
      select2SearchField.style.height = '60px'
      select2SearchField.style.top = '-19px'
      select2SearchField.style.paddingLeft = '50px'
      select2SearchField.style.maxWidth = '400px'
      select2Results.style.maxWidth = '400px'
      select2SearchField.style.background = 'linear-gradient(to left, white 42%, white 90%, transparent 91%)'
    }
  }
}

