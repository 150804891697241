import { Controller } from '@hotwired/stimulus';
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = [
    'budgetSlider',
    'budgetLow',
    'budgetHigh',
    'budgetLowValue',
    'budgetHighValue',
    'surfaceSlider',
    'surfaceLow',
    'surfaceHigh',
    'surfaceLowValue',
    'surfaceHighValue'
  ];

  static values = {
    budgetLow: Number,
    budgetHigh: Number,
    surfaceLow: Number,
    surfaceHigh: Number
  }

  connect() {
    this.createSliders();
  }

  createSliders = () => {
    setTimeout(() => {
      const btnSlider = document.querySelector('.noUi-handle-lower');
      btnSlider && btnSlider.setAttribute('aria-label', 'button slider');
    }, 100);

    if(this.hasBudgetSliderTarget) {
      noUiSlider.create(this.budgetSliderTarget, {
        start: [(this.budgetLowValue || 0), (this.budgetHighValue || 3000000)],
        step: 10_000,
        connect: true,
        range: {
          min: 0,
          max: 3_000_000,
        },
      });

      this.budgetSliderTarget.noUiSlider.on('update', (values, _) => {
        this.budgetLowTarget.value = Math.floor(values[0]).toString()
        this.budgetLowValueTarget.innerHTML = numberWithSpaces(Math.floor(values[0]).toString())
        this.budgetHighTarget.value = Math.floor(values[1]).toString()
        this.budgetHighValueTarget.innerHTML = numberWithSpaces(Math.floor(values[1]).toString())
      })
    };
   
    if(this.hasSurfaceSliderTarget) {
      noUiSlider.create(this.surfaceSliderTarget, {
        start: [(this.surfaceLowValue || 0), (this.surfaceHighValue || 300)],
        step: 1,
        connect: true,
        range: {
          min: 0,
          max: 300,
        },
      });

      this.surfaceSliderTarget.noUiSlider.on('update', (values, _) => {
        this.surfaceLowTarget.value = Math.floor(values[0])
        this.surfaceLowValueTarget.innerHTML = Math.floor(values[0])
        this.surfaceHighTarget.value = Math.floor(values[1])
        this.surfaceHighValueTarget.innerHTML = Math.floor(values[1])
      });
    }
  };
}
