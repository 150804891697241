import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filters', 'arrow']

  toggleFiltersDisplay() {
    this.filtersTarget.classList.toggle('vesta-display-none')
    this.arrowTarget.classList.toggle('fa-angle-down')
    this.arrowTarget.classList.toggle('fa-angle-up')
  }
}
