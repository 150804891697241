import { Controller } from '@hotwired/stimulus';

import SET_MAP from '../config/mapbox/setMap'
import SET_MARKERS from '../config/mapbox/setMarkers'
import SET_POPUP from '../config/mapbox/setPopup';

export default class extends Controller {
  static values = { url: String,
                    zones: Array,
                    cta: String,
                    publicKey: String,
                    style: String };

  connect() {
    this.map = null;
    this.initMapbox();
    this.searchZone();
  }

  initMapbox() {
    this.map = SET_MAP.initMap(this.publicKeyValue, [1.7191, 47.7833], 4.5, this.styleValue, 'mapbox-zones')

    this.map.on('load', () => {
      this.map.setLayoutProperty('country-label', 'text-field', ['get', 'name_fr']);
      this.map.setLayoutProperty('state-label', 'text-field', ['get', 'name_fr']);
      this.map.setLayoutProperty('settlement-subdivision-label', 'text-field', ['get', 'name_fr']);
    });

    this.map.doubleClickZoom.disable();

    this.zonesValue.forEach((zoneInfos) => {
      const popup = this.createPopup(zoneInfos[2]);
      SET_MARKERS.main(this.map, [zoneInfos[1], zoneInfos[0]], popup)
    });
  }

  searchZone() {
    if ($('#search-zone')) {
      $('#search-zone').on('select2:select', (e) => {
        const closePopup = document.querySelector('.mapboxgl-popup-close-button');
        const zoneName = e.params.data.text;
        const coordinates = e.params.data.id.split('&');

        this.lat = coordinates[0].split('=')[1];
        this.lng = coordinates[1].split('=')[1];

        if (closePopup) closePopup.click();

        this.map.flyTo({ center: [this.lng, this.lat], zoom: 12 });

        if (zoneName) {
          this.createPopup(zoneName).setLngLat([this.lng, this.lat]).addTo(this.map);
        }
      });
    }
  }

  createPopup = (zoneName) => {
    // TODO LATER => handle responsive with popupOffsets, poputip (arrow) and vesta-tooltip
    // https://docs.mapbox.com/mapbox-gl-js/api/markers/#popup-example
    const popupOffsets = {
      top: [0, 0],
      'top-left': [0, 0],
      'top-right': [0, 0],
      bottom: [0, 0],
      'bottom-left': [0, 0],
      'bottom-right': [0, 0],
      left: [0, 0],
      right: [0, 0]
    };

    const HTMLContent =
      `
      <a href="${this.urlValue}" class='vesta-tooltip-anchor-wraper'>
        <div class="vesta-tooltip fade_animation">
          <div class="vesta-tooltip--container-img">
            <img src="https://res.cloudinary.com/vesta-home/image/upload/v1642581991/assets/images/revamp%202022/images/tooltip_img.webp" alt="tooltip"></div>
            <div class="vesta-tooltip--container-content">
            <h4 class="typo-h4">${zoneName}</h4>
            <div class="typo-bold-content vesta-blue-100">${this.ctaValue}</div>
          </div>
        </div>
      </a>
      `

    return SET_POPUP.init(popupOffsets, HTMLContent)
  };
}
