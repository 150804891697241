import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  process(event) {
    let success  = navigator.clipboard.writeText(event.target.dataset.buyerphone);
    let message  = 'Numéro de téléphone copié ✅';
    let msg_kind = 'success';

    if (success) {
      this.solicitate(event.target.dataset.buyerid);
    } else {
      let message  = 'Erreur lors de la copie du numéro de téléphone ❌';
      let msg_kind = 'error';
    }

    if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(message, msg_kind);
  }

  solicitate = async (buyerId) => {
    const response = await fetch('/admin/users_solicitations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ users_solicitation: { user_id: buyerId } })
    })

    if (response.status == 200) {
      const data = await response.json();
      document.getElementById(`solicited_by_${buyerId}`).innerHTML = data.success_text;
    } else {
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(response.message, 'error');
    }
  }
}
