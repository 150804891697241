import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'inputField', 'window' ];

  connect = () => {
    this.scrollDown()
  }

  sendMessage = async(e) => {
    e.preventDefault()

    const chatBox = document.querySelector('#vesta-flatsy-chat');
    // Save input message before deleting it for front purpose
    const message = this.inputFieldTarget.value;
    this.inputFieldTarget.value = ''
    // Insert Message in chatbox for UX purpose (even if not sent)
    const messageHTML = `
      <div class='hosmanian-message-container'>
        <div class='spinner-loader-centered'></div>
        <div class='vesta-bold-content bubble-message bubble-message--hosmanian'>${message}</div>
      </div>
    `
    chatBox.insertAdjacentHTML('beforeend', messageHTML);
    this.scrollDown();

    const response = await fetch(`/admin/ventes/${this.windowTarget.dataset.saleid}/flatsy/messages`, {
      method: "POST",
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'Content-Type': 'application/json'},
      credentials: 'same-origin',
      body: JSON.stringify({ message: message, sale_id: this.windowTarget.dataset.saleid})
    })

    const status = await response.status
    const lastMessage = chatBox.lastElementChild;

    if(status === 500) {
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage("Échec de l'envoi du message. Vérifiez que le bien est sur Flatsy", 'error')
      // Add error notif if message not sent
      const errorNotif = `
        <div class='typo-italic typo-small'>
          <img src='https://res.cloudinary.com/vesta-home/image/upload/v1659084514/assets/revamp/icons/wrong-circle.svg' title='message non envoyé'/>Non envoyé. Vérifier que le bien existe sur Flatsy</div>
        `
      lastMessage.insertAdjacentHTML('beforeend', errorNotif)
    } else {
      // Add created at date if message sent
      const data = await response.json();
      const dateNotif = `<div class='typo-italic typo-small'>${data.created_at}</div>`
      lastMessage.insertAdjacentHTML('afterbegin', dateNotif)
    }
    const spinnerEl = document.querySelector('.spinner-loader-centered');
    spinnerEl.remove();
  }

  scrollDown = () => {
    this.windowTarget.scrollTop = this.windowTarget.scrollHeight;
  }
}
