const typingEffect = () => {
  const div = document.getElementById('dynamic-typing-word');
  if (div) {
    const listWord = div.dataset.listWord;
    const array = listWord.split(', ');

    const typer = new autoTyper({
      selector: '#dynamic-typing-word',
      words: array,
      charSpeed: 100, // typing speed
      delay: 2800, // typing delay
      loop: true, // infinite loop
      flipflop: false, // enable flipflop effect
    });

    typer.start();
  }
}

export default typingEffect;
