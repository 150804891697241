import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['price', 'email', 'copyBtn', 'generateBtn', 'loadingLayer', 'emailDiv'];
  static values = { url: String, uniqueHash: String };

  generate()  {
    this.loadingLayerTarget.classList.remove('hidden')
    this.generateBtnTarget.disabled = true
    this.handleGa4Event()
    this.populateEmail()
  }

  populateEmail = () => {
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token':     document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type':     'application/json',
        'Accept':           'application/json'
      },
      body: JSON.stringify({sale: {price: this.priceTarget.value}}),
      credentials: 'same-origin',
    }).then((response) => {
      if (response.status == 200) {
        response.json().then(data => {
          this.loadingLayerTarget.classList.add('hidden')
          this.emailTarget.innerHTML = `${data.content.replace(/\n/g, '<br>').replace(/\*\*(.*?)\*\*/g, "<span class='typo-bold-content'>$1</span>")}`
          this.emailDivTarget.classList.remove('vesta-display-none')
          this.copyBtnTarget.classList.remove('hidden')
        })
      } else {
        response.json().then(data => {
          if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(data['error'], 'error');
          this.generateBtnTarget.disabled = false
          this.loadingLayerTarget.classList.add('hidden')
        });
      }
    }
  )
  }

  enableGenerateBtn() {
    if (this.priceTarget.value.length > 0) {
      this.generateBtnTarget.disabled = false       
    } else {
      this.generateBtnTarget.disabled = true
    }
  }

  copy () {
    try {
      const fontStyle = "font-family: Poppins, Verdana, sans-serif; font-size: 14px"
      const emailContent = this.emailTarget.innerHTML.replace(/<span class="typo-bold-content">(.*?)<\/span>/g, '<b>$1</b>');
      const styledHtmlText = `<span style="${fontStyle}">${emailContent}</span>`
      
      const blob = new Blob([styledHtmlText], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];
      
      let success = navigator.clipboard.write(data);
      let message = success ?  'Vous avez copié le texte ✅' : "Le texte n'a pas été copié ❌";
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(message, 'success')
    } catch (error) {
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(error.message, 'error')
    }
  }
 
  handleGa4Event = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'priceDecreaseEmailGenerated',
      'unique_hash': this.uniqueHashValue
    })
  }
}
