import { Controller } from '@hotwired/stimulus';

import { fetchProperties } from '../../api/properties'

import SET_MAP from '../../config/mapbox/setMap'
import SET_LAYERS from '../../config/mapbox/setLayers'
import SET_MARKERS from '../../config/mapbox/setMarkers'

import UPDATE_LAYERS from './functions/updateLayers'

import { generateLayerId, filterZone, fillPaint, linePaint, SOURCE_ID } from '../henriette/mapbox_options'

const COLORS = { 'blue-100': '#5076F6', 'purple-100': '#21175A', 'neutral-30': '#4B485F', 'neutral-40': '#BCBAC3', transparent: 'transparent' };

export default class extends Controller {
  static values = {
    publicKey: String,
    style: String,
    tilesetId: String,
    propertyLat: String,
    propertyLng: String,
    quarterLat: String,
    quarterLng: String,
    hosmanSectorId: Number,
    avgPriceHouse: String,
    avgPriceApartment: String,
    propertyKind: String,
    zoom: Number
  }

  static targets = [
    'generalContent',
    'customContent',
    'soldPropertiesCount',
    'soldPropertiesCountTitle',
    'awaitingOfferPropertiesCount',
    'propertiesCount',
    'avgPrice',
    'awaitingOfferPropertiesCard',
    'soldPropertiesText',
    'awaitingOfferPropertiesText',
    'avgPriceCard'
  ]

  connect = async () => {
    await this.handleProperties()
    if (this.properties.length > 3) this.customContentTargets.forEach(content => content.classList.remove('vesta-display-none'))
    else this.generalContentTarget.classList.remove('vesta-display-none')
    this.mapboxMap = SET_MAP.initMap(this.publicKeyValue, [this.quarterLngValue, this.quarterLatValue], this.zoomValue ? this.zoomValue : 13, this.styleValue, 'sales-sector-map')
    this.mapboxMapLoaded()
    await this.handleMarkers()
    await this.handleCardContent()
    // await this.handleGeoDatum()
    if (window.matchMedia("(max-width: 430px)").matches) this.handleMapWithSmallScreen()
  }

  mapboxMapLoaded = () => {
    this.mapboxMap.on('load', async () => {
      SET_MAP.initSource(this.mapboxMap, 'geo-datas', this.tilesetIdValue)

      SET_LAYERS.withOptions({
          map: this.mapboxMap,
          layerId: generateLayerId('fill', 'quarter'),
          sourceId: SOURCE_ID,
          type: 'fill',
          filter: filterZone('quarter'),
          paint: fillPaint(COLORS['purple-100'], COLORS['blue-100'])
      });

      SET_LAYERS.withOptions({
        map: this.mapboxMap,
        layerId: generateLayerId('line', 'quarter'),
        sourceId: SOURCE_ID,
        type: 'line',
        filter: filterZone('quarter'),
        paint: linePaint()
      });

      UPDATE_LAYERS.paintSector(this.mapboxMap, 'geo-datas-fill-quarter', 'fill-color', this.hosmanSectorIdValue, COLORS['blue-100'])
      UPDATE_LAYERS.paintSector(this.mapboxMap, 'geo-datas-line-quarter', 'line-color', this.hosmanSectorIdValue, COLORS['blue-100'])
      UPDATE_LAYERS.paintSector(this.mapboxMap, 'geo-datas-line-quarter', 'line-width', this.hosmanSectorIdValue, 3, 0)
    })
  }

  handleProperties = async () => {
    this.properties = await fetchProperties(this.hosmanSectorIdValue);
    this.propertiesCount = this.properties.length
    this.propertiesSoldCount = this.properties.filter(property => property.state === 'sold').length
    this.propertiesAwaitingOfferCount = this.propertiesCount - this.propertiesSoldCount
  }

  handleGeoDatum = async () => {
    const avgPrice = this.numberToCurrency(this.propertyKindValue === 'house' ? Number(this.avgPriceHouseValue) : Number(this.avgPriceApartmentValue))
    if (this.hasAvgPriceTarget) this.avgPriceTarget.innerHTML = avgPrice
  }

  handleMarkers = () => {
    // handle main marker
    SET_MARKERS.main(this.mapboxMap, [this.propertyLngValue, this.propertyLatValue])
    // handle others marker
    const soldPropertiesCount = this.properties.filter(property => property.state === 'sold').length
    if (soldPropertiesCount > 0) this.properties.forEach(property => SET_MARKERS.specific(this.mapboxMap, property))
  }

  handleCardContent = () => {
    if (this.hasPropertiesCountTarget) this.propertiesCountTarget.innerHTML = this.propertiesCount
    if (this.hasSoldPropertiesCountTarget) this.soldPropertiesCountTarget.innerHTML = `${this.propertiesSoldCount < 10 ? 0 : ''}${this.propertiesSoldCount}`
    if (this.hasSoldPropertiesCountTitleTarget) this.soldPropertiesCountTitleTarget.innerHTML = this.propertiesSoldCount
    if (this.propertiesAwaitingOfferCount === 0) this.awaitingOfferPropertiesCardTarget.classList.add('vesta-display-none')
    if (this.hasAwaitingOfferPropertiesCountTarget) this.awaitingOfferPropertiesCountTarget.innerHTML = `${this.propertiesAwaitingOfferCount < 10 ? 0 : ''}${this.propertiesAwaitingOfferCount}`
    if (this.hasSoldPropertiesTextTarget) this.soldPropertiesTextTarget.innerHTML = `Bien${this.propertiesSoldCount > 1 ? 's' : ''} vendu${this.propertiesSoldCount > 1 ? 's' : ''}`
    if (this.hasAwaitingOfferPropertiesTextTarget) this.awaitingOfferPropertiesTextTarget.innerHTML = `Bien${this.propertiesAwaitingOfferCount > 1 ? 's' : ''} à vendre`
  }

  handleMapWithSmallScreen = () => {
    this.mapboxMap.scrollZoom.disable();
    this.mapboxMap.dragPan.disable();
  }

  numberToCurrency = (number) => {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0  });
  }
}
