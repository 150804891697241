import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    buyerAlert: Object,
    zones: Array,
    user: Object
  }

  connect() {
    const buyer_alert = this.buyerAlertValue
    const zones = this.zonesValue
    const user = this.userValue

    const property_kind = []
    if(buyer_alert.apartment) { property_kind.push('apartment') }
    if(buyer_alert.house) { property_kind.push('house') }

    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'complete_buyer_alert',
      'property_kind': property_kind,
      'search_zones': zones.map(zone => zone.replace(/_/g, ' ')),
      'min_budget': buyer_alert.min_budget,
      'max_budget': buyer_alert.max_budget,
      'min_area': buyer_alert.min_area,
      'min_bedroom': buyer_alert.min_bedroom_number,
      'min_room': buyer_alert.min_room_number,
      'floor': buyer_alert.floor,
      'lift': buyer_alert.min_floor_for_elevator,
      'potential_work': buyer_alert.renovation,
      'outdoor_area': buyer_alert.outdoor_space,
      'purchase_type': buyer_alert.purchase_type,
      'situation': user.situation,
      'project_state': user.project_state,
      'user_id': user.id,
      'user_email': user.email,
      'user_phone': user.phone_number,
      'user_first_name': user.first_name,
      'user_last_name': user.last_names
    })
  }
}
