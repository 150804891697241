const handleStopPropagation = () => {
  const stopPropagationBlock = document.querySelector('#stopPropagationBlock')
  if (stopPropagationBlock) {
    stopPropagationBlock.addEventListener('click', function(event) {
      event.stopPropagation();
      event.preventDefault();
    });
  }
}

export default handleStopPropagation;
