import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal']

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const duplicateLead = urlParams.get('duplicate_lead_slug');

    if (duplicateLead !== null) $('#duplicate-leads-warning').modal('show');
  }

  hideModal() {
    $('#duplicate-leads-warning').modal('hide')
  }
}
