import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image']

  connect() {
    // Set height of parent element to height of images element
    const childElement = this.imageTargets[0]
    const childHeight = childElement.offsetHeight;
    const parentElement = document.querySelector('.vesta-header-container')
    parentElement.style.height = childHeight + 'px';

    this.crossFadeImages = this.imageTargets
    this.randomize(this.crossFadeImages.length);
    const activeImage = this.setImageOnLoad(this.randomNumber);
    this.setNextImage(activeImage);
  }

  randomize = (arrayLength) => {
    this.randomNumber = Math.floor(arrayLength * Math.random());
  }

  setImageOnLoad = (path) => {
    this.crossFadeImages[path].classList.add(
      'crossfade-image--active'
    );
    const active = document.querySelector('.crossfade-image--active');
    return active;
  }

  setNextImage = (activeImage) => {
    setTimeout(() => {
      activeImage.classList.remove('crossfade-image--active');

      if (activeImage.nextElementSibling !== null) this.nextImage = activeImage.nextElementSibling;
      else this.nextImage = document.querySelector('.crossfade-image');

      this.nextImage.classList.add('crossfade-image--active');
      setTimeout(() => {
        this.setNextImage(this.nextImage);
      }, 2000);
    }, 5000);
  }
}
