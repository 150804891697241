import { Controller } from '@hotwired/stimulus';
import SET_MAP from '../config/mapbox/setMap';
import SET_MARKERS from '../config/mapbox/setMarkers';

export default class extends Controller {
  static values = {
    propertyLat: String,
    propertyLng: String,
    style: String,
    publicKey: String,
    approximateLocation: Boolean,
    zoom: Number
  };

  connect() {
    this.handleCenter()
    this.initZoom()
    this.handleMap();
    this.handleMarker();
    this.handleZoom()
  }

  handleMap = () => {
    this.map = SET_MAP.initMap(this.publicKeyValue, this.center, this.zoom, this.styleValue, 'vesta-mapbox')
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  handleMarker = () => {
    if (this.approximateLocationValue === true) {
      this.marker = SET_MARKERS.main(this.map, [this.propertyLngValue, this.propertyLatValue], '','hosman-marker--approx-location')
    } else {
      SET_MARKERS.main(this.map, [this.propertyLngValue, this.propertyLatValue])
    }
  };

  handleCenter() {
    if (this.hasApproximateLocationValue && this.approximateLocationValue) this.center = [Math.floor(this.propertyLngValue * 1000 + 2) / 1000, Math.floor(this.propertyLatValue * 1000 + 2)]
    this.center = [this.propertyLngValue, this.propertyLatValue]
  }

  initZoom() {
    if (this.hasZoomValue) this.zoom = this.zoomValue
    else if (this.hasApproximateLocationValue && this.approximateLocationValue) this.zoom = 12
    else this.zoom = 11
  }

  handleZoom() {
    if(this.marker === undefined) return

    this.map.on('zoom',  () => {
      const zoom = this.map.getZoom();
      const baseMarkerSize = 600;
      const scalingFactor = 0.5;

      const scaledMarkerSize = baseMarkerSize * Math.pow(scalingFactor, this.zoomValue - zoom);
      if (scaledMarkerSize < 30) return

      this.marker.getElement().style.width = scaledMarkerSize + 'px';
      this.marker.getElement().style.height = scaledMarkerSize + 'px';
    });
  }
}
