import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sellerContent', 'sellerButton', 'buyerContent', 'buyerButton'];
  static values = {
    type: String
  };

  switchToSeller = () => {
    if (this.typeValue === 'seller') return;

    this.typeValue = 'seller';
    this.toggleSwitchBtn();
  };

  switchToBuyer = () => {
    if (this.typeValue === 'buyer') return;

    this.typeValue = 'buyer';
    this.toggleSwitchBtn();
  };

  toggleSwitchBtn = () => {
    if (this.typeValue === 'seller') {
      this.handleContentDisplay(this.sellerContentTarget, this.buyerContentTarget);
      this.handleButtonSwitch(this.sellerButtonTarget, this.buyerButtonTarget);
    }

    if (this.typeValue === 'buyer') {
      this.handleContentDisplay(this.buyerContentTarget, this.sellerContentTarget);
      this.handleButtonSwitch(this.buyerButtonTarget, this.sellerButtonTarget);
    }
  };

  handleContentDisplay = (activeContent, inactiveContent) => {
    activeContent.classList.remove('vesta-switch-display-none');
    inactiveContent.classList.add('vesta-switch-display-none');
  };

  handleButtonSwitch = (activeButton, inactiveButton) => {
    activeButton.classList.add('vesta-active-switch');
    activeButton.classList.remove('vesta-inactive-switch');
    inactiveButton.classList.add('vesta-inactive-switch');
    inactiveButton.classList.remove('vesta-active-switch');
  };
}
