async function fetchProperties(hosmanId) {
  const params = {
    q: { sale_state_eq_any: ['awaiting_offer', 'sold'],
         sale_quarter_id_eq: hosmanId,
         parking_or_garage_eq: false }
        };

  const response = await fetch(`/api/v1/properties/coordinates?${querifyObject(params)}`, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  const data = await response.json();
  return data;
}

async function fetchProperty(propertyId) {
  const response = await fetch(`/api/v1/properties/${propertyId}`, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  const data = await response.json();
  return data;
}

export { fetchProperties, fetchProperty };

function querifyObject(object, parent = null) {
  return Object.keys(object)
    .map((key) => {
      const value = object[key];
      const full_key = fullKey(key, parent);

      if (Array.isArray(value)) return value.map((v) => toQuery(full_key + '[]', v)).join('&');
      else if (typeof value === 'object') return querifyObject(value, full_key);
      else return toQuery(full_key, value);
    })
    .join('&');
}

function toQuery(key, value) {
  return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function fullKey(key, parent) {
  if (parent) return parent + '[' + key + ']';
  else return key;
}
