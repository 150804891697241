const citiesSearch = () => {
  if ($.fn.select2) {
    $.fn.select2.amd.define('select2/data/hosmanAutocompleteAdapter', ['select2/data/array', 'select2/utils'],
      function (ArrayAdapter, Utils) {
        function HosmanAutocompleteDataAdapter ($element, options) {
          HosmanAutocompleteDataAdapter.__super__.constructor.call(this, $element, options);
        }

        Utils.Extend(HosmanAutocompleteDataAdapter, ArrayAdapter);

        HosmanAutocompleteDataAdapter.prototype.query = function (params, callback) {
          var data = {results: []};
          if (params.term && params.term != '') {
            const url = `/api/v1/cities?q[name_or_zip_codes_agg_cont_any]=${params.term}`
            const fetchCitiesPredictions = (url) => {
              fetch(url, {
                  method: 'GET',
                  headers: { 'Content-Type': 'text/html', 'Accept': 'text/html' },
                  credentials: 'same-origin'
              }).then((response) => {
                if (response.status == 200) {
                  response.json().then((predictions) => {
                    for (var i = 0; i< predictions.length; i++) {
                      data.results.push({ id: predictions[i].id, text: predictions[i].name });
                    }
                  });
                  setTimeout(() => {
                    callback(data);
                  }, 1);

                } else {
                  callback(data);
                }
              })
            }

            fetchCitiesPredictions(url)
          } else {
            callback(data);
          };
        };
        return HosmanAutocompleteDataAdapter;
      }
    );

    var hosmanAutocompleteAdapter = $.fn.select2.amd.require('select2/data/hosmanAutocompleteAdapter');

    $('.select-2-address-custom-autocomplete').select2({
      width: '100%',
      dataAdapter: hosmanAutocompleteAdapter,
      placeholder: 'Entrez une ou plusieurs villes ou arrondissements',
      escapeMarkup: function (markup) { return markup; },
      minimumInputLength: 2,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
  }

  function formatRepo(repo) {
    if (repo.loading) {
        return repo.text;
    }
    var markup = "<div class='select2-result-repository clearfix'>" +
        "<div class='select2-result-title'>" + repo.text + "</div>";
    return markup;
  }

  function formatRepoSelection(repo) {
    return repo.text;
  }
}

export default citiesSearch;
