import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // static targets = ['link'];

  connect() {
    // Change trix CTA pointing to lead (related to seller/buyer guide)
    this.guideCardBlockV1 = document.getElementsByTagName("guide_card_block_v1");
    if(this.guideCardBlockV1[0] && this.guideCardBlockV1[0].innerText.toLowerCase().includes('commencer mon estimation')) {
      this.getLastChildrenNodes();
      this.createSpanEl();

      this.lastChildrenNode = this.getLastChildrenNodes();
      if(this.lastChildrenNode.innerText.toLowerCase().includes('commencer mon estimation')) {
        this.changeLastChildrenNode();
      }
    }
  }

  getLastChildrenNodes = () => {
    return this.guideCardBlockV1[0].children[this.guideCardBlockV1[0].children.length - 1]
  }

  createSpanEl = () => {
    this.spanEl = document.createElement('span');
    this.spanEl.className = 'btn';
    this.spanEl.classList.add('btn-primary', 'btn-lg', 'link-obfuscation');
    this.spanEl.innerHTML = "Commencer mon estimation";
    this.spanEl.dataset.action = 'click->application--obfuscation-link#decodeUrlLinks'
    this.spanEl.dataset.encode = btoa('https://app.hosman.co/estimation');
    this.spanEl.dataset.blank = 'true';
  }

  changeLastChildrenNode = () => {
    this.lastChildrenNode.remove();
    this.lastChildrenNode = this.getLastChildrenNodes();
    this.lastChildrenNode.parentNode.insertBefore(this.spanEl, this.lastChildrenNode.nextSibling);
  }

  decodeUrlLinks = (e) => {
    let EncodedUrl = e.currentTarget.dataset.encode;
    let DecodedUrl = atob(EncodedUrl);
    window.open(DecodedUrl, e.currentTarget.dataset.blank === 'true' ? '_blank' : '_self');
  }
}
