const expertsCarousel = () => {
  if (document.getElementById('experts-slick-carousel')) {
    $('#experts-slick-carousel').slick({
      lazyLoad: 'ondemand',
      infinite: true,
      slidesToShow: $('#experts-slick-carousel')[0].dataset.expertsNumber,
      slidesToScroll: 1,
      prevArrow: $('.experts-slick-carousel-prev'),
      nextArrow: $('.experts-slick-carousel-next'),

      responsive: [
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
  }
}

export default expertsCarousel;
