import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect = () => {
    setTimeout(() => {
      const selectionRendered = document.querySelector('.custom-phone-select2');
      if (selectionRendered) {
        if (selectionRendered.nextElementSibling) {
          const selected = selectionRendered.nextElementSibling.querySelector('.select2-selection__rendered');
          selected.textContent = selected.textContent.split(' ')[0];
        }
      }
    }, 100);
  }
}
