const localStorageHandler = () => {
  const localStorageGclid = localStorage.getItem('gclid');
  const gclid = new URLSearchParams(window.location.search).get('gclid');

  const gclidNotStoredYet = localStorageGclid === null && gclid !== null
  const differentGclidStored = gclid !== null && localStorageGclid !== gclid

  if (gclidNotStoredYet || differentGclidStored) localStorage.setItem('gclid', gclid);
}

export default localStorageHandler;
