import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'input' ];

  toggleVisibility(e) {
    const type = this.inputTarget.getAttribute('type') === 'password' ? 'text' : 'password';
    this.inputTarget.setAttribute('type', type);
    e.currentTarget.classList.toggle('fa-eye-slash')
    e.currentTarget.classList.toggle('fa-eye')
  }
}
