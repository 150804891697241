import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'marqueeContent', 'marqueeItem' ];

  connect() {
    this.marqueeItemTargets.forEach(item => {
      const clone = item.cloneNode(true);
      this.marqueeContentTarget.appendChild(clone);
    });
  }
}
