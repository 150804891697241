import { Controller } from '@hotwired/stimulus';
import SET_MAP from '../config/mapbox/setMap';
import SET_MARKERS from '../config/mapbox/setMarkers';

export default class extends Controller {
  static values = {
    publicKey: String,
    style: String,
    propertyLat: String,
    propertyLng: String,
    zoom: Number,
  }

  connect() {
    this.initMap();

    SET_MARKERS.main(this.mapboxMap, [this.propertyLngValue, this.propertyLatValue])

    if (window.matchMedia("(max-width: 430px)").matches) this.handleMapWithSmallScreen()

    this.mapboxMap.addControl(new mapboxgl.NavigationControl());

    $('a[data-toggle="tab"]').on('shown.bs.tab', e => this.mapboxMap.resize())

    const ficheCsCta = document.querySelector('a[data-target-title="fiche_cs"]')
    if (ficheCsCta) ficheCsCta.addEventListener('click', () => this.mapboxMap.resize())
  }

  initMap = () => {
    const center = [this.propertyLngValue, this.propertyLatValue]
    this.mapboxMap = SET_MAP.initMap(this.publicKeyValue,
                                      center,
                                      this.zoomValue,
                                      this.styleValue,
                                      'mapbox-map');
  }

  handleMapWithSmallScreen = () => {
    this.mapboxMap.scrollZoom.disable();
    this.mapboxMap.dragPan.disable();
  }
}
