import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = ['nextBtnSlide', 'prevBtn'];

  connect = () => {
    this.swiper = new Swiper('.swiper-available-slots', {
      slidesPerView: 2,
      navigation: {
        nextEl: '.next-slot',
        prevEl: '.prev-slot'
      },
      on: {
        init: () => {
          this.handleSlidesButton();
        },
        slideChange: () => {
          this.handleSlidesButton();
        },
      },
      breakpoints: {
        380: {
          slidesPerView: 3
        },
        1100: {
          slidesPerView: 5
        },
        1200: {
          slidesPerView: 7
        }
      }
    });
  };

  handleSlidesButton = () => {
    if (!this.swiper) return

    const isLastSlide = this.swiper.isEnd;
    const isFirstSlide = this.swiper.isBeginning;
    const prevSlide = document.querySelector('.swiper-button-prev-custom')
    const nextSlide = document.querySelector('.swiper-button-next-custom')

    this.toggleSlides(prevSlide, isFirstSlide);
    this.toggleSlides(nextSlide, isLastSlide);
  }

  toggleSlides = (slideEl, firstOrLastSlide) => {
    if (!slideEl) return;

    if (firstOrLastSlide) {
      slideEl.classList.add('swiper-button-disabled');
    } else {
      slideEl.classList.remove('swiper-button-disabled');
    }
  }
}
