import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['default', 'scrolled']
  static values = {
    dropdownType: String,
    scrollInactive: String
  }

  connect() {
    this.prevScrollPosition = window.pageYOffset;
  }

  scroll = () => {
    // Doesn't apply nav changes for admin
    if ((this.hasScrollInactiveValue && this.scrollInactiveValue === 'true') || !this.hasDefaultTarget) return

    const currentScrollPosition = window.pageYOffset;
    if (this.prevScrollPosition > currentScrollPosition && currentScrollPosition > 400) {
      this.defaultTarget.classList.remove('vesta-display-none--custom');
      this.scrolledTarget.classList.add('vesta-display-none--custom');
    } else if (this.prevScrollPosition < currentScrollPosition && currentScrollPosition > 400) {
      this.defaultTarget.classList.add('vesta-display-none--custom');
      this.scrolledTarget.classList.remove('vesta-display-none--custom');
    }

    this.prevScrollPosition = currentScrollPosition;
  }

  openDropdown = () => {
    const chevronEl = document.querySelector(`i[data-chevron-target="${this.dropdownTypeValue}"]`);
    this.dropdownEl = document.querySelector(`div[data-dropdown-target="${this.dropdownTypeValue}"]`);
    if (this.dropdownEl) {
      this.dropdownEl.classList.add('active');
      if (chevronEl) chevronEl.className = chevronEl.className.replace('down', 'up');
    }
  }

  closeDropdown = () => {
    this.dropdownOpen = document.querySelector('.dropdown-menu-container.active');
    const chevronUp = document.querySelector('.fas.fa-chevron-up.menu');
    if (chevronUp) chevronUp.className = chevronUp.className.replace('up', 'down');
    if (this.dropdownOpen) this.dropdownOpen.classList.remove('active');
  }

  closeDropdownOnDocument = (e) => {
    if (e.target.classList.contains('fa-user') || e.target.classList.contains('vesta-user-initials') || e.target.dataset.chevronTarget === 'avatar-mobile') return;
    this.closeDropdown();
  }

  toggleDropdownMobile = () => {
    this.dropdownOpen = document.querySelector('.dropdown-menu-container.active');
    if(this.dropdownOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }
}
