import { Controller } from '@hotwired/stimulus';
import { agencyFees } from '../../shared/helpers/agencyFeesHelper';

export default class extends Controller {
  static targets = ['feesForm', 'price', 'newFees', 'newFeesValue', 'newFeesInput']
  static values = {
    parisRegion: Boolean,
    parkingKind: Boolean,
    noExclusivity: Boolean,
    currentFees: Number,
    currentPrice: Number
  }

  connect() {
    this.displayFees()
  }

  checkChangePrice() {
    if (this.currentPriceValue != this.priceTarget.value) this.displayFees();
  }

  displayFees() {
    const newFees = this.parkingKindValue ? 2500 : agencyFees(this.parisRegionValue, parseInt(this.priceTarget.value.replaceAll(' ', '')), this.noExclusivityValue)

    if (newFees !== this.currentFeesValue && this.priceTarget.value) {
      this.feesFormTarget.classList.remove('vesta-display-none')
      this.newFeesValueTarget.value = newFees
      this.newFeesInputTarget.value = newFees.toLocaleString('fr-FR')
      this.newFeesTarget.innerText = newFees.toLocaleString('fr-FR', { style: "currency", currency: "EUR", maximumFractionDigits: 0 })
    } else {
      this.newFeesValueTarget.value = newFees
      this.newFeesInputTarget.value = newFees.toLocaleString('fr-FR')
      this.feesFormTarget.classList.add('vesta-display-none')
    }
  }
}
