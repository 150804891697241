import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    const select = this.selectTarget;
    if (select) {
      const placeholder = select.options[0];
      placeholder.hidden = true;
      placeholder.disabled = true;
    }
  }
}
