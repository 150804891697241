import { Controller } from '@hotwired/stimulus';
import priceFormatting from '../../shared/utils/priceFormatting';
export default class extends Controller {
  static targets = ['basicTarif', 'premiumTarif', 'premiumService', 'basicService', 'basicServiceExplanation', 'premiumServiceExplanation', 'houseOption', 'premiumMinPrice'];
  static values = {
    offerType: String,
    basicTarif: String,
    premiumTarif: String,
    basicTarifRegion: String,
    premiumTarifRegion: String,
  };

  switchToPremium = () => {
    if (this.offerTypeValue === 'premium') return;

    this.offerTypeValue = 'premium';
    this.toggleSwitchBtn();
  };

  switchToEssential = () => {
    if (this.offerTypeValue === 'essential') return;

    this.offerTypeValue = 'essential';
    this.toggleSwitchBtn();
  };

  toggleSwitchBtn = () => {
    const activeSwitch = document.querySelector('.vesta-active-switch.vesta-tarifs');
    const inactiveSwitch = document.querySelector('.vesta-inactive-switch.vesta-tarifs');
    activeSwitch.classList.remove('vesta-active-switch');
    activeSwitch.classList.add('vesta-inactive-switch');
    inactiveSwitch.classList.remove('vesta-inactive-switch');
    inactiveSwitch.classList.add('vesta-active-switch');
    this.basicServiceTarget.classList.toggle('vesta-switch-display-none');
    this.premiumServiceTarget.classList.toggle('vesta-switch-display-none');
    this.basicServiceExplanationTarget.classList.toggle('vesta-switch-display-none');
    this.premiumServiceExplanationTarget.classList.toggle('vesta-switch-display-none');
    this.houseOptionTarget.classList.toggle('vesta-switch-display-none');
    this.premiumMinPriceTarget.classList.toggle('vesta-switch-display-none');
  };

  offerTypeValueChanged(value) {
    if (value === 'essential') {
      this.handleTarifChanges(this.basicTarifValue, this.basicTarifRegionValue);
    } else if (value === 'premium') {
      this.handleTarifChanges(this.premiumTarifValue, this.premiumTarifRegionValue);
    }
  }

  handleTarifChanges = (tarifValue, premiumTarifValue) => {
    this.basicTarifTarget.textContent = priceFormatting(tarifValue);
    this.premiumTarifTarget.textContent = priceFormatting(premiumTarifValue);
  };
}
