import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['saledoc', 'formContainer'];

  initialize = async () => {
    this.setSpinner(this.formContainerTarget);
    await this.prepareSaledocTargetsHtml();
    this.removeSpinner();
    this.formContainerTarget.classList.toggle('hidden');
  }

  connect = async () => {
    await this.prepareSaledocTargetsHtml();
  };

  prepareSaledocTargetsHtml = () => {
    return new Promise((resolve, reject) => {
      this.saledocTargets.forEach((saledocTarget, index) => {
        let labelEl = saledocTarget.querySelector('label');
        const isBtnInDom = labelEl.querySelector('.removeTrash');
        const btnContainer = labelEl.querySelector('.btn-container');
        if(isBtnInDom === null) {
          const removeSaledocBtn = `
                <span class="removeTrash ${saledocTarget.classList.contains('saledoc-card--success') ? '' : 'hidden' }">
                  <i class="far fa-edit" data-action='click->application--onboarding-seller#removeSaledoc' title="Supprimer le document"></i>
                </span>
                `;
          btnContainer.insertAdjacentHTML('beforeend', removeSaledocBtn);
        }

        setTimeout(() => {
          let inputField = saledocTarget.querySelector('input[type="file"]');
          let editBtnEl = saledocTarget.querySelector('.fa-edit');
          let trashBtnEl = saledocTarget.querySelector('.fa-trash');

          // Add saledoc index to each DOM elements to interact with
          labelEl.setAttribute('data-saledoc-index', `${index}`);
          if(editBtnEl !== null) editBtnEl.setAttribute('data-saledoc-index', `${index}`);
          if(trashBtnEl !== null) trashBtnEl.setAttribute('data-saledoc-index', `${index}`);
          saledocTarget.setAttribute('data-saledoc-index', `${index}`);
          inputField.setAttribute('data-saledoc-index', `${index}`);

          inputField.setAttribute(
            'data-action',
            'input->application--onboarding-seller#uploadDoc'
          );

          document
            .querySelectorAll('.add-relation-button')
            .forEach((relationBtn) =>
              relationBtn.setAttribute('data-doc-num', `${index}`)
            );
          resolve();
        }, 500);
      });
    });
  };

  setSpinner = (parentEl) => {
    this.spinnerEl = `
      <div class='spinner-loader-centered spinner-loader-big'></div>
    `;
    parentEl.insertAdjacentHTML('beforebegin', this.spinnerEl);
  };

  removeSpinner = () => {
    const spinnerEl = document.querySelector(`.spinner-loader-centered`);
    spinnerEl.remove();
  };

  // Trigger the connect method each time a new saledoc is added to the DOM in order to refresh saledoc targets number
  addSaledocContainer = () => {
    setTimeout(() => {
      this.connect();
    }, 1000);
  };

  uploadDoc = (e) => {
    const input = e.target;
    const fileName = input.files[0]?.name;
    const saledocContainer = document.querySelector(`.saledoc-card[data-saledoc-index="${e.target.dataset.saledocIndex}"]`)
    // If card already has style of uploaded doc, don't toggle it. Only toggle if file is removed or newly added
    if (fileName && saledocContainer.classList.contains('saledoc-card--success')) return

    this.toggleUploadStateCss(saledocContainer);
  }

  toggleUploadStateCss = (saledocContainer) => {
    saledocContainer.classList.toggle('saledoc-card--success');
    let icon = saledocContainer.querySelector('i');
    let trashEl = saledocContainer.querySelector('.removeTrash');
    icon.classList.toggle('hidden');
    trashEl.classList.toggle('hidden');
  };

  removeSaledoc = (e) => {
    this.toggleUploadStateCss(
      document.querySelector(
        `.saledoc-card[data-saledoc-index="${e.target.dataset.saledocIndex}"]`
      )
    );
  };
}
