const SET_LAYERS = {
  withOptions({ map, layerId, type, sourceId, filter, paint }) {
    map.addLayer({
      id: layerId,
      type,
      source: sourceId,
      'source-layer': 'Hosman',
      filter: filter,
      paint: paint
    });
  },
};

export default SET_LAYERS;
