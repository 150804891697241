async function fetchContent(url, body) {
  const response =  await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'text/html',
      'Content-Type': 'application/json'
    },
    body
  });

  return await response.text();
}

export { fetchContent };
