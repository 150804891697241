import Swiper from 'swiper/bundle';
// TODO 1 use stimulus controller to use it only when needed
// TODO 2 import only necessary modules (check lazy)

const swiperJs = () => {
  new Swiper('.last-sales-carousel', {
    slidesPerView: 1,
    spaceBetween: 40,
    centeredSlides: false,
    navigation: {
      nextEl: '.next-sale',
      prevEl: '.prev-sale'
    },
    breakpoints: {
      640: {
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 5
      },
      1024: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 100
      },
      1550: {
        centeredSlides: false,
        slidesPerView: 4,
        spaceBetween: 100
      }
    }
  });

  new Swiper('.last-sales-carousel-side-title', {
    spaceBetween: 30,
    slidesPerView: 'auto',
    speed: 800,
    loop: true,

    navigation: {
      nextEl: '.next-sale',
      prevEl: '.prev-sale'
    },
  });

  new Swiper('.strong-points', {
    slidesPerView: 1,
    spaceBetween: 90,
    navigation: {
      nextEl: '.next-review',
      prevEl: '.prev-review'
    }
  });

  new Swiper('.strong-points-seller', {
    slidesPerView: 1,
    spaceBetween: 90,
    navigation: {
      nextEl: '.next-review',
      prevEl: '.prev-review'
    }
  });

  new Swiper('.strong-points-buyer', {
    slidesPerView: 1,
    spaceBetween: 90,
    navigation: {
      nextEl: '.next-step-buyer',
      prevEl: '.prev-step-buyer'
    }
  });

  new Swiper('.experts-list-swiper', {
    slidesPerView: 2,
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper-button-next-expert',
      prevEl: '.swiper-button-prev-expert'
    }
  });

  new Swiper('.strong-points-premium', {
    slidesPerView: 1,
    spaceBetween: 90,
    navigation: {
      nextEl: '.next-review-premium',
      prevEl: '.prev-review-premium'
    }
  });

  new Swiper('.similar-sales', {
    slidesPerView: 3,
    spaceBetween: 10,
    navigation: {
      nextEl: '.next-sales',
      prevEl: '.prev-sales'
    }
  });

  new Swiper('.sale-steps-swiper', {
    slidesPerView: 'auto',
    speed: 1000,
    navigation: {
      nextEl: '.next-step',
      prevEl: '.prev-step'
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    breakpoints: {
      980: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.next-step',
          prevEl: '.prev-step'
        },
        mousewheel: true
      }
    }
  });
};

export default swiperJs;
