import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'accordion' ]

  connect = () => {
    const defaultActiveDropdowns = document.querySelectorAll('.dropdown-container.active');

    if (defaultActiveDropdowns) {
      [...defaultActiveDropdowns].forEach((activeDropdown) => {
        const icon = activeDropdown.querySelector('.fa-chevron-right');
        if (icon) {
          icon.className = icon.className.replace('right', 'up');
          const panel = activeDropdown.nextElementSibling;
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }
  }

  toggle = (e) => {
    const activeAccordion = document.querySelector('.dropdown-container.active');
    const panel = e.currentTarget.nextElementSibling;
    e.currentTarget.classList.toggle('active');
    const icon = document.querySelector('.fa-chevron-up');
    if (icon) icon.className = icon.className.replace('up', 'right');
    // To handle toggle if the current active accordion is the one clicked again (close the accordion in this case)
    if (activeAccordion && activeAccordion !== e.currentTarget) {
      activeAccordion.classList.remove('active');
      activeAccordion.nextElementSibling.style.maxHeight = null;
    }
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      const icon = e.currentTarget.querySelector('.fa-chevron-right');
      if (icon) icon.className = icon.className.replace('right', 'up');
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  toggleAccordion = (e) => {
    const panel = e.currentTarget.nextElementSibling;
    e.currentTarget.classList.toggle('active');
    const icon = e.currentTarget.querySelector('.fa-chevron-up');
    if (icon) icon.className = icon.className.replace('up', 'right');
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      const icon = e.currentTarget.querySelector('.fa-chevron-right');
      if (icon) icon.className = icon.className.replace('right', 'up');
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
