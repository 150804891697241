import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = ['swiper'];
  static values = {
    secondPhoto: String
  };

  connect = () => {
    new Swiper('.swiper-premium-reviews', {
      centeredSlides: true,
      loop: true,
      effect: 'fade',
      speed: 1000,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      spaceBetween: 10,
      navigation: {
        nextEl: '.vesta-next-review',
        prevEl: '.vesta-prev-review'
      }
    });
  };
}
