import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'keyInput']

  connect() {
    this.enable()
  }

  enableSubmit() {
    this.enable()
  }

  enable() {
    if (this.keyInputTarget.value) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
