import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tabTitle']

  triggerTab = (e) => {
    const activeBtnTab = document.querySelector('.vesta-button-tab--active')
    activeBtnTab.classList.remove('vesta-button-tab--active')
    activeBtnTab.classList.add('vesta-button-tab')
    e.currentTarget.classList.add('vesta-button-tab--active')
    e.currentTarget.classList.remove('vesta-button-tab')

    const tabContentTarget = document.getElementById(e.currentTarget.dataset.targetTitle)
    if(tabContentTarget.classList.contains('vesta-tab-active')) return

    const activeTab = document.querySelector('.vesta-tab-active')
    activeTab.classList.remove('vesta-tab-active')
    tabContentTarget.classList.add('vesta-tab-active')
  }
}
