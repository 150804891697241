import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

let INDEX = 0;
export default class extends Controller {
  static targets = ['swiper'];
  static values = {
    secondPhoto: String
  };

  connect = () => {
    this.swiperTargets.forEach(() => {
      INDEX += 1;
      new Swiper(`.swiper-last-sale-carousel-photos-${INDEX}`, {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        spaceBetween: 10,
        nested: true,
        navigation: {
          nextEl: `.next-sale-photo-${INDEX}`,
          prevEl: `.prev-sale-photo-${INDEX}`
        }
      });
    });
  };
}
