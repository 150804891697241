import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
   static targets = [ 'landingZoneCity',
                      'landingZoneZipCode',
                      'landingZoneLat',
                      'landingZoneLng',
                      'geoFullAddress',
                      'geoStreetName',
                      'geoStreetNumber',
                      'geoZipCode',
                      'geoCity',
                      'geoCountryCode',
                      'geoLat',
                      'geoLng',
                      'displayButton',
                      'explainDisplayFields' ]

  connect = () => {
    if (this.hasLandingZoneCityTarget) {
      var placesOptions = { types: ['geocode'], componentRestrictions: { country: 'fr' } };
      this.set_up_autocomplete(this.landingZoneCityTarget, this.populateZoneAddress, placesOptions);
    } else {
      var restriction = this.data.get('country-restriction');
      if (restriction !== null && restriction != undefined) {
        var placesOptions = { types: ['geocode'], componentRestrictions: { country: restriction } };
      } else {
        var placesOptions = { types: ['geocode'] };
      }
      this.set_up_autocomplete(this.geoFullAddressTarget, this.populateAddress, placesOptions)
    }

    [this.geoStreetNameTarget, this.geoStreetNumberTarget, this.geoZipCodeTarget, this.geoCityTarget, this.geoCountryCodeTarget].forEach((target) => {
      target.parentNode.classList.add('hidden')
    })
  }

  set_up_autocomplete = (target, populateAddress, placesOptions) => {
    // restrict API calls to selected countries
    this.autocomplete = new google.maps.places.Autocomplete(target, placesOptions);
    // restrict API calls to useful components
    this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry.location']);
    this.autocomplete.addListener('place_changed', populateAddress);
    // google.maps.event.addListener(autocomplete, 'place_changed', populateZoneAddress);
    // Do not submit the form on Enter.
    google.maps.event.addDomListener(target, 'keydown', function (e) {
      if (e.key === "Enter") { e.preventDefault(); }
    });
  }

  // populate detailed address fields
  populateAddress = () => {
    // remove fast the previous flash message if any to allow potential new message
    if (document.getElementsByClassName('iziToast-capsule').length > 0) {
      let toast = document.querySelector('.iziToast'); // Selector of your toast
      iziToast.hide({}, toast);
    }

    // const geoInputs = document.getElementById('geo-inputs');
    const place = this.autocomplete.getPlace();
    const components = this.getAddressComponents(place);

    // start formating full address field
    this.geoFullAddressTarget.blur();

    this.geoFullAddressTarget.value = '';
    if (components.address) { this.geoFullAddressTarget.value = components.address.split(', ')[0] }

    if (components.zip_code) {
      if (this.geoFullAddressTarget.value === '') {
        this.geoFullAddressTarget.value = components.zip_code
      } else {
        this.geoFullAddressTarget.value = this.geoFullAddressTarget.value + ', ' + components.zip_code
      }
    }

    if (components.city) {
      if (this.geoFullAddressTarget.value === '') {
        this.geoFullAddressTarget.value = components.city
      } else {
        this.geoFullAddressTarget.value = this.geoFullAddressTarget.value + ', ' + components.city
      }
    }

    if (!place.address_components.some(component => component.types.includes('locality')) ||
        !place.address_components.some(component => component.types.includes('route'))) {
          this.geoFullAddressTarget.value = place.formatted_address;
        }
    // end formating full address field

    this.geoStreetNumberTarget.value = components.street_number;
    this.geoStreetNameTarget.value = components.street_name;
    this.geoZipCodeTarget.value = components.zip_code;
    this.geoCityTarget.value = components.city;
    this.geoCountryCodeTarget.value = components.country_code;
    this.geoLatTarget.value = components.lat;
    this.geoLngTarget.value = components.lng;
  }

  populateZoneAddress = () => {
    const place = this.autocomplete.getPlace();
    const components = this.getAddressComponents(place);

    this.landingZoneCityTarget.value = components.city;
    this.landingZoneZipCodeTarget.value = components.zip_code;
    this.landingZoneLatTarget.value = components.lat;
    this.landingZoneLngTarget.value = components.lng;
  }

  // fetch detailed address fields
  getAddressComponents = (place) => {
    let street_number = null;
    let route = null;
    let zip_code = null;
    let city = null;
    let country_code = null;
    let country_full = null;

    place.address_components.forEach((component) => {
      component.types.forEach((type) => {
        if (type === 'street_number') {
          street_number = component.long_name;
        } else if (type === 'route') {
          route = component.long_name;
        } else if (type === 'postal_code') {
          zip_code = component.long_name;
        } else if (type === 'locality') {
          city = component.long_name;
        } else if (type === 'postal_town' && city === null) {
          city = component.long_name;
        } else if (type === 'country') {
          country_code = component.short_name;
          country_full = component.long_name;
        }
      })
    })

    if (route === null) {
      if (place.address_components.some(component => component.types.includes('sublocality'))) {
        route = ''
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (type === 'sublocality') {
              route += `${component.long_name} `
            }
          })
        })
      }
    }

    if (city === null) {
      if (place.address_components.some(component => component.types.includes('administrative_area_level_1'))) {
        city = ''
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (type === 'administrative_area_level_1') {
              city = component.long_name
            }
          })
        })
      }
    }

    return {
      address: street_number === null ? route : ((street_number || '') + ' ' + route + ', ' + city + ', ' + (country_full || '')),
      zip_code: zip_code,
      city: city,
      country_code: country_code,
      street_number: street_number,
      street_name: route,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
  }

  displayFields = (e) => {
    const targets = [this.geoStreetNameTarget, this.geoStreetNumberTarget, this.geoZipCodeTarget, this.geoCityTarget, this.geoCountryCodeTarget]
    targets.forEach((target) => {
      target.parentNode.classList.toggle('hidden');
    });

    e.currentTarget.innerText =
      e.currentTarget.innerText === 'Afficher les champs détaillés'
      ? 'Cacher les champs détaillés'
      : 'Afficher les champs détaillés';

    this.explainDisplayFieldsTarget.classList.toggle('hidden');
  }
}
