import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = ['output']
  static values = { background: Boolean, url: String }

  connect() {
    this.fetchReviews();
  }

  fetchReviews = async () => {
    const request = new Request(this.urlValue);
    try {
      const response = await fetch(request)
      // get content from response
      const content = await response.text();
      // fill partial with content
      this.outputTarget.innerHTML = await content;
      // then trigger carousel
      await new Swiper('.trustpilot-reviews', {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.vesta-next-review',
          prevEl: '.vesta-prev-review'
        },
        breakpoints: {
          1660: {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
              nextEl: '.vesta-next-review',
              prevEl: '.vesta-prev-review'
            }
          },
          910: {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
              nextEl: '.vesta-next-review',
              prevEl: '.vesta-prev-review'
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
