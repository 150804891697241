// NOTE: one can pass in triggers whatever data is useful for identification
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'hiddenPart', 'triggeringPart' ]

  connect() {
    const hiddenTarget = this.hiddenPartTarget;
    const triggeringTarget = this.triggeringPartTarget;
    const triggers = hiddenTarget.dataset.triggers.split(',');

    if (triggers.includes(triggeringTarget.value) && hiddenTarget.classList.contains('hidden')) {
      hiddenTarget.classList.remove('hidden');
    }
  }

  check(e) {
    const hiddenTarget = this.hiddenPartTarget;
    const triggers = hiddenTarget.dataset.triggers.split(',');

    if (triggers.includes(e.target.value) && hiddenTarget.classList.contains('hidden')) {
      hiddenTarget.classList.remove('hidden');
    }

    if (!triggers.includes(e.target.value) && !hiddenTarget.classList.contains('hidden')) {
      hiddenTarget.classList.add('hidden');
    }
  }
}
