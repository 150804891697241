import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.i18n.js';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cleaveMoneyInput'];

  connect() {
    // format input when DOM is loaded
    for (let field of this.cleaveMoneyInputTargets) {
      this.initFormatMoneyInput(field);
    }
  }

  formatMoneyInput = (dataActionEvent) => {
    this.initFormatMoneyInput(dataActionEvent.target);
  };

  initFormatMoneyInput = (field) => {
    new Cleave(field, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      delimiter: ' ',
      numeralDecimalScale: 0,
      onValueChanged: (inputEvent) => {
        if (field) {
          field.nextElementSibling.value = inputEvent.target.rawValue;
        }
      }
    });
  };

  formatDateInput = (e) => {
    new Cleave(e.target, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y']
    });
  };

  formatPhoneNumberInput = (e) => {
    new Cleave(e.target, {
      numeral: true,
      phone: true,
      phoneRegionCode: document.querySelector('.vesta-cleave-phone-code select').value || 'FR',
      delimiter: ' '
    });
  };
}
