import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tooltip'];

  changeSelection(event) {
    const reasonType = event.currentTarget.id;
    const selectedReason = event.currentTarget.value;
    let selectedTooltip = document.getElementById(reasonType + "_" + selectedReason)

    this.tooltipTargets.forEach(tooltip => tooltip.style.display = 'none');

    if (selectedTooltip)
      selectedTooltip.style.display = 'block';
  }
}
