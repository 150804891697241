import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  connect() {
    this.fetchSales();
  }

  fetchSales = async () => {
    const headers = {
      'X-CSRF-Token':     document.getElementsByName('csrf-token')[0].getAttribute('content'),
      'X-Requested-With': 'XMLHttpRequest',
      'Accept':           'text/html'
    }

    try {
      const response = await fetch(
        '/pages/sales', {
          method: 'GET',
          headers: headers,
          credentials: 'same-origin'
        }
      )
      // get content from response
      const content = await response.text();
      // fill partial with content
      document.getElementById('sales-carousel-remote').innerHTML = await content;
      // then trigger carousel
      await new Swiper('.last-sales-carousel', {
        slidesPerView: 1,
        spaceBetween: 40,
        centeredSlides: false,
        navigation: {
          nextEl: '.next-sale',
          prevEl: '.prev-sale'
        },
        breakpoints: {
          640: {
            centeredSlides: false,
            slidesPerView: 2,
            spaceBetween: 5
          },
          1024: {
            centeredSlides: false,
            slidesPerView: 3,
            spaceBetween: 100
          },
          1550: {
            centeredSlides: false,
            slidesPerView: 4,
            spaceBetween: 100
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
