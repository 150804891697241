import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'publicationInput', 'modalButton', 'submitButton' ]

  initialize() {
    this.handlePriceWarning()
  }

  handlePriceWarning = () => {
    if (!this.modalButtonTarget || !this.submitButtonTarget) return;

    if (this.publicationInputTarget.checked) {
      this.modalButtonTarget.classList.remove('hidden')
      this.submitButtonTarget.classList.add('hidden')
    } else {
      this.modalButtonTarget.classList.add('hidden')
      this.submitButtonTarget.classList.remove('hidden')
    }
  }
}
