import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['loadingLayerSizedAbsolute']

  spinIt = (event) => {
    if (event.target.classList.contains('trigger-flatsy-state-spinner')) {
      this.loadingLayerSizedAbsoluteTarget.style.display = ''
    }
  }
}
