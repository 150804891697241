import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'value',
    'result',
    'active',
  ]

  static values = {
    sale: Number,
    total: Number,
  }

  connect = () => {
    this.updateCount()
  }

  updateCount = () => {
    const value = this.valueTarget.value || this.valueTarget.innerHTML

    if (!value) {
      this.resultTarget.innerHTML = '-'
      return
    }

    fetch(
      `/admin/ventes/${this.saleValue}/active_buyers/count?value=${value}`,
      { credentials: 'same-origin' }
    ).then((response) => {
      response.json().then((result) => {
        this.resultTarget.innerHTML = result.count || '-'
        if (this.hasActiveTarget && result.active !== undefined) {
          this.activeTarget.innerHTML = result.active
        }
      });
    });
  }
}
