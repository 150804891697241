import consumer from "./consumer"
const flatsyChatContainer = document.querySelector('#vesta-flatsy-chat');

if(flatsyChatContainer) {
  consumer.subscriptions.create({
    channel: 'FlatsyChatsChannel',
    sale_id: flatsyChatContainer.dataset.saleid
    }, {
    received(data) {
      // Called when there's incoming data on the websocket for this channel
      const trigger = document.getElementById('flatsy-chat-channel-trigger');
      const chatBox = document.querySelector('#vesta-flatsy-chat');
      if (!trigger) return null;

      if (trigger.dataset.controller == 'admin/sales/flatsy' && trigger.dataset.action == 'show' ) {
        const message = `
          <div class="${data.hosmanian === true ? 'hosmanian-message-container' : 'flatguide-message-container'}">
            <div class='typo-italic typo-small'>${data.created_at}</div>
            <div class="vesta-bold-content ${data.hosmanian === true ? 'bubble-message bubble-message--hosmanian' : 'bubble-message bubble-message--flatguide'}">${data.message}</div>
            ${data.not_sent ? "<div class='typo-italic typo-small'><img src='https://res.cloudinary.com/vesta-home/image/upload/v1659084514/assets/revamp/icons/wrong-circle.svg' title='message non envoyé'/>Non envoyé. Vérifier que le bien existe sur Flatsy</div>" : ''}
          </div>
        `
        chatBox.insertAdjacentHTML('beforeend', message);
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    }
  });
}
