import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];
  static values = { translateY: String };

  connect() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.fadeIn(entry.target);
        } else {
          this.fadeOut(entry.target);
        }
      });
    }, { threshold: 0.2 });

    this.elementTargets.forEach(element => {
      observer.observe(element);
      element.style.opacity = '0';
      if (this.hasTranslateYValue) element.style.transform = `translateY(${this.translateYValue})`;
    });
  }

  fadeIn(element) {
    element.style.transition = 'all 0.5s ease-in-out';
    element.style.opacity = '1';
    element.style.transform = 'translateY(0px)';
  }

  fadeOut(element) {
    element.style.opacity = '0';
    element.style.transform = 'translateY(20px)';
  }
}
