import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'saleNewNotary',
    'saleNewNotaryClerc',
    'saleEditNotary',
    'offerNewNotary',
    'offerNewNotaryClerc',
    'offerEditNotary',
  ]

  displayForm(event) {
    const formClicked = this.targets.find(event.currentTarget.dataset.form)
    this.constructor.targets.filter(target => {
      const notCurrent = target !== event.currentTarget.dataset.form;
      const isForm = target.match(/NewNotary|EditNotary/);
      return (notCurrent && isForm);
    }).forEach((target) => {
      const form = this.targets.find(target)
      if (form) {
        form.classList.add('vesta-display-none')
      }
    });

    formClicked.classList.toggle('vesta-display-none')
  }
}
