import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  initialize() {
    this.checkDevice();
    if (this.device === 'mobile') this.initializeMobileCarousel()
    this.initializeModalCarousel()
  }

  checkDevice() {
    const userAgent = navigator.userAgent;

    if (/mobile|iPad|Tablet/i.test(userAgent)) this.device = 'mobile'
  }

  initializeModalCarousel() {
    this.initializeThumbCarousel();
    this.initializeMainCarousel();
    this.carouselUpdate();
  }

  initializeThumbCarousel() {
    this.carouselGenericThumb = new Swiper('.carousel-generic-thumb', {
      loop: true,
      freeMode: true,
      watchSlidesProgress: true,
      touchRatio: 0.2,
      slidesPerView: 3,
      spaceBetween: 5,
      initialSlide: 0,
      breakpoints: {
        990: {
          slidesPerView: 5,
          spaceBetween: 10
        }
      }
    });
  }

  initializeMainCarousel() {
    this.carouselGenericMain = new Swiper('.carousel-generic-main', {
      loop: true,
      navigation: {
        nextEl: '.next-slide',
        prevEl: '.prev-slide'
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      speed: 500,
      thumbs: {
        swiper: this.carouselGenericThumb
      },
      keyboard: {
        enabled: true
      },
      initialSlide: 0
    });
  }

  initializeMobileCarousel() {
    this.carouselGenericThumbMobile = new Swiper('.carousel-generic-thumb-mobile', {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true
    });

    this.carouselGenericMainMobile = new Swiper('.carousel-generic-main-mobile', {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.next-slide',
        prevEl: '.prev-slide'
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: this.carouselGenericThumbMobile
      }
    });
  }

  carouselUpdate() {
    // Hack to make the thumbnail click work on the main carousel if the thumbnail is clicked before the main carousel. otherwise, the main carousel will not update as the thumbnail
    if (this.device === 'mobile') this.handleClickThumbnail(this.carouselGenericThumbMobile, this.carouselGenericMainMobile)
    else this.handleClickThumbnail(this.carouselGenericThumb, this.carouselGenericMain)

    // Update the main carousel when the modal is shown (to make sure it's updated with proper index of clicked image)
    $('#ModalCarousel').on('shown.bs.modal', () => {
      this.carouselGenericMain.update();
      this.carouselGenericThumb.update();
      this.carouselGenericMain.slideTo(this.clickedIndex);
      this.carouselGenericThumb.slideTo(this.clickedIndex);
    });
  }

  handleClickThumbnail(thumbCarousel, modalCarousel) {
    thumbCarousel.on('click', () => {
      setTimeout(() => {
        modalCarousel.update();
      }, 100);
    });

    modalCarousel.on('click', () => {
      setTimeout(() => {
        thumbCarousel.update();
      }, 100);
    });
  }

  clickPhoto(event) {
    this.clickedIndex = parseInt(event.currentTarget.dataset.index);
  }
}
