import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'hosmanOverlayMenu' ]

  toggle = (e) => {
    this.hosmanOverlayMenuTarget.classList.toggle('active');
    e.currentTarget.classList.toggle('active');
  }
}
