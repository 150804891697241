import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['containerFixedBtn', 'fixedCta'];

  connect() {
    this.initializeIntersectionObserver();
  }

  initializeIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(this.handleObserver, options);
    if (this.containerFixedBtnTarget) {
      observer.observe(this.containerFixedBtnTarget);
    }
  }

  handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      this.fixedCtaTarget.classList.add('vesta-show-button');
    }
    else {
      this.fixedCtaTarget.classList.remove('vesta-show-button');
    }
  }
}
