import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'replacementExpertFields', 'endReplacement' ]

  displayReplacementExpertFields(e) {
    const replacementExpertFields = this.replacementExpertFieldsTarget;
    const endReplacement = this.endReplacementTarget;

    if (e.target.checked == true) {
      replacementExpertFields.classList.remove('hidden');
    } else {
      // remove selected replacement expert
      document.getElementById('sale_replacement_expert_id').selectedIndex = null;
      // fetch all inputs composing the date
      const dateFields = endReplacement.querySelectorAll('select');
      // remove data from day, month and year
      dateFields.forEach(function(dateField) {
        dateField.selectedIndex = null;
      });
      // hide the fields
      replacementExpertFields.classList.add('hidden');
    }
  }
}
