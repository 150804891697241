import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  displayHint = (event) => {
    const newKind = event.currentTarget.value;
    const container = event.currentTarget.closest('.sales-user-fields-container');
    const otherHints = container.querySelectorAll('.kind-hint');
    const newHint = container.querySelector('.kind-hint-' + newKind);

    otherHints.forEach((el) => { el.classList.add('hidden') });
    if (newHint) newHint.classList.remove('hidden');
  }
}
