import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'apartmentProperty',
    'selectedHouseKind',
    'selectedApartmentKind'
  ]

  checks = (e) => {

    let houseKind = this.selectedHouseKindTarget;
    let apartmentKind = this.selectedApartmentKindTarget;

    let select_min_floor = document.getElementById('user_buyer_alerts_attributes_0_min_floor_for_elevator');
    let select_floor = document.getElementById('user_buyer_alerts_attributes_0_floor');

    e.currentTarget.classList.toggle('selected');
    if (houseKind.classList.contains('selected') && apartmentKind.classList.contains('selected')) {
      if(this.apartmentPropertyTarget.classList.contains('vesta-invisible')) {
        this.apartmentPropertyTarget.classList.toggle('vesta-invisible');
      }
    }
    else if (houseKind.classList.contains('selected')) {
      select_min_floor.selectedIndex = 0;
      select_floor.selectedIndex = 0;
      this.apartmentPropertyTarget.classList.toggle('vesta-invisible');
    }
    else {
      if (this.apartmentPropertyTarget.classList.contains('vesta-invisible')) {
        this.apartmentPropertyTarget.classList.toggle('vesta-invisible');
      }
    }
  }
}
