import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['containerNumbers', 'numberKeys'];

  connect() {
    this.initializeIntersectionObserver();
  }

  initializeIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '200px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(this.handleObserver, options);
    if (this.containerNumbersTarget) {
      observer.observe(this.containerNumbersTarget);
    }
  };

  handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      this.animateNumber();
    }
  };

  animateNumber = () => {
    const speed = 200;
    this.numberKeysTargets.forEach((block) => {
      const animate = () => {
        const value = +block.getAttribute('number');
        const data = +block.innerText;
        const time = value / speed;

        if (data < value) {
          block.innerText = Math.ceil(data + time);
          setTimeout(animate, 1);
        } else {
          block.innerText = value;
        }
      };
      animate();
    });
  };
}
