import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['scrollContainer']

  connect = () => {
    this.scrollMax = this.scrollContainerTarget.clientWidth;
    this.scrollAmount = 0;
    this.scrollMin = 0;
    this.scrollStep = 500;
    // Auto scroll to the timeline end
    this.scrollContainerTarget.scrollLeft = this.scrollContainerTarget.scrollWidth
  }

  scroll = (e) => {
    this.scrollAmount = e.target.scrollLeft;
  }
}
