import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['loadingLayer', 'generateButton', 'advertContent', 'advertTitle']

  static values = {
    url: String
  }

  generateAdvert = () => {
    this.loadingLayerTarget.classList.remove('hidden')
    this.generateButtonTarget.disabled = true
    this.populateAdvert()
  }

  populateAdvert = () => {
    fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token':     document.getElementsByName('csrf-token')[0].getAttribute('content'),
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type':     'application/json',
          'Accept':           'application/json'
        },
        credentials: 'same-origin'
      }).then((response) => {
        if (response.status == 200) {
          response.json().then(data => {
            this.advertTitleTarget.value = data.title;
            this.advertContentTarget.value = data.content;
            this.loadingLayerTarget.classList.add('hidden')
          })
        } else {
          response.json().then(data => {
            if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(data['error'], 'error');
            this.generateButtonTarget.disabled = false
            this.loadingLayerTarget.classList.add('hidden')
          });
        }
      }
    )
  }
}
