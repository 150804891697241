import Isotope from 'isotope-layout';
import { Controller } from '@hotwired/stimulus';

// X-selling
export default class extends Controller {
  static targets = [ 'filterContainer', 'filterCategory' ]

  connect() {
    this.isotope = new Isotope( this.filterContainerTarget, {
      // options
      itemSelector: '.item',
      layoutMode: 'fitRows',
      transitionDuration: 900
    });
    this.isotope.arrange({
      filter: '*'
    })
  }

  filter = (e) => {
    const filterValue = e.target.dataset.filter;
    const selector = document.getElementsByClassName('vesta-filter-category');
    const target = e.target;
    if (selector) {
      for (var element of selector) {
        if (element.classList.contains('active')) {
          element.classList.remove('active');
        }
      }
    }
    target.classList.add('active');
    this.isotope.arrange({
      filter: filterValue
    });
    window.location.hash = `${filterValue}`;
  }

  selectFilter = (e) => {
    const selectedOption = e.target.options[e.target.options.selectedIndex].value;
    this.isotope.arrange({
      filter: selectedOption
    });
  };
}
