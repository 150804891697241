import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    link: String
  }

  copyText(e) {
    e.preventDefault();
    this.text = e.currentTarget.dataset.value;
    this.alertUser();
  }

  copyInput(e) {
    e.preventDefault();
    this.text = e.currentTarget.querySelector('input').value;
    this.alertUser();
  }

  copy() {
    this.text = this.linkValue;
    this.alertUser();
  }

  alertUser() {
    try {
      let success = navigator.clipboard.writeText(this.text);
      let message = success ?  'Vous avez copié le texte ✅' : "Le texte n'a pas été copié ❌";
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(message, 'success')
    } catch (error) {
      if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(error.message, 'error')
    }
  }
}
