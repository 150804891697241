import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'infoSpan' ]

  connect() {
    const headers = {
      'X-CSRF-Token':     document.getElementsByName('csrf-token')[0].getAttribute('content'),
      'X-Requested-With': 'XMLHttpRequest',
      'Accept':           'text/html'
    }

    try {
      fetch(
        this.infoSpanTarget.dataset.action, {
          method: 'GET',
          headers: headers,
          credentials: 'same-origin'
        }
      ).then((response) => {
        response.text().then((content) => {
          document.getElementById('mandate-signatories').innerHTML = content;
        });
      });
    } catch (error) {
      alertMessage(error, 'error');
    }
  }
}
